import React from "react";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import "./Slider3.scss";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useSelector } from "react-redux";
import { GET_PRODUCT_LIST_FOR_USER, globalAction } from "../../redux";
import { useDispatch } from "react-redux";

function Slider3(props) {
  let history = useHistory();
  let dispatch = useDispatch();
  const { currentCountry } = useSelector((state) => state.appReducer);

  const handleBrandClick = (e, brandId) => {
    localStorage.setItem("brand_id", brandId);
  };

  const { title, data, items, firstItemBig } = props;
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    slidesToScroll: items,
    slidesToShow: items,
    pauseOnHover: true,
    nextArrow: <GrNext />,
    prevArrow: <GrPrevious />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="slider3-wrap">
      <div className="slider-heading-wrap">
        <h2>{title} </h2>
      </div>
      <div className="slider-wrap-inner-3 clearfix">
        <Slider {...settings}>
          {data !== undefined &&
            data.map((item, i) => {
              return (
                <div
                  className={`slider3-img-wrap ${
                    firstItemBig && i % items === 0 ? "item-big" : ""
                  }`}
                >
                  <a
                    href={
                      item.slug
                        ? `/product-detail/${item.slug || ""}`
                        : `/products`
                    }
                    onClick={(e) => {
                      if (item.slug === undefined) {
                        handleBrandClick(e, item.id);
                      }
                    }}
                  >
                    <div className="slider-item3">
                      <div className="slider3-img">
                        <img
                          src={item.image_url || item.image}
                          alt={item.title || item.name}
                        />
                      </div>

                      <div className="content-wrap">
                        <div>
                          <p className="sold_by">
                            <small>Brand: </small>
                            <em>{item.brand_name}</em>
                          </p>
                        </div>
                        <h4>{item.title || item.name}</h4>

                        {item?.sale_price !== null ? (
                          <p>
                            <span className="saleprice">
                              {item?.sale_price ? `${item?.sale_price}$` : ""}
                            </span>{" "}
                            &nbsp;&nbsp;
                            <del className="regular-price">
                              {item && item.regular_price != item.sale_price
                                ? item.regular_price + "$"
                                : ""}
                            </del>
                          </p>
                        ) : (
                          <p>
                            <span className="saleprice">
                              {item?.regular_price} $
                            </span>
                          </p>
                        )}

                        {item.product_discount && (
                          <div className="tag-wrap">
                            <span>{item.product_discount}</span>
                          </div>
                        )}
                        {/* {item?.user?.company_name
                                                    ?
                                                    <a  href={`/vendor-store/${item?.user?.id}`}>
                                                        <p className="sold_by">
                                                            <small>Sold by: </small>
                                                            {item?.user?.company_name}
                                                        </p>
                                                    </a>
                                                    :
                                                    <a  href={`/`}>
                                                        <p className="sold_by">
                                                            <small>Sold by: </small>
                                                            Du-Afrik
                                                        </p>
                                                    </a>
                                                }


                                                <div >
                                                    <p className="sold_by">
                                                        <small>M.no : {item.model_no}</small>
                                                    </p>
                                                </div> */}
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
}

export default Slider3;
