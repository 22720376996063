import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import whiteBg from "../../assets/img/whiteBg.png";
import CmAlert from "../../components/CmAlert/CmAlert";
import Loading from "../../components/Loading/Loading";
import {
  FORGET_PASSWORD,
  globalAction,
  RESET_PASSWORD,
  SHOW_LOADING,
} from "../../redux";
import "./ForgetPassword.scss";
import axios from "axios";

function ForgetPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { visibleMessage, isLoading, message, status } = useSelector(
    (state) => state.errorReducer
  );
  const [userEmail, setUserEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [checkEmpty, setcheckEmpty] = useState(false);
  const [formStep, setFormStep] = useState(1);

  useEffect(() => {
    window.scroll({ top: 0 });
    if (message === "We have e-mailed your password reset code!") {
      setFormStep(2);
    }
  }, [visibleMessage]);

  const handleSubmit = () => {
    if (userEmail.trim() === "") {
      setUserEmail(undefined);
      setcheckEmpty(true);
    } else {
      dispatch(globalAction(SHOW_LOADING));
      dispatch(
        globalAction(FORGET_PASSWORD, { email: userEmail, history: history })
      );
      setcheckEmpty(false);
    }
  };

  const handleVerifyOTP = () => {
    if (
      OTP.trim() === "" ||
      password.trim() === "" ||
      cpassword.trim() === ""
    ) {
      setOTP(undefined);
      setcheckEmpty(true);
    } else {
      //   dispatch(globalAction(SHOW_LOADING));
      //   dispatch(
      //     globalAction(RESET_PASSWORD, {
      //       email: userEmail,
      //       forgot_otp: OTP,
      //       new_password: password,
      //       confirm_password: cpassword,
      //       history: history,
      //     })
      //   );
      const body = {
        email: userEmail,
        forgot_otp: OTP,
        new_password: password,
        confirm_password: cpassword,
        history: history,
      };
      axios
        .post("http://duafric-admin.devshs.com/api/reset_password", body)
        .then((res) => {
          console.log(res, "res res");
          if (res?.data?.status === "error") {
            window.alert(res?.data?.message);
          } else if (res?.data?.status === "success" || "Success") {
            alert(res?.data?.message);
            history.push("/login");
          }
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            window.alert(err?.response?.data?.errors?.confirm_password);
          } else {
            window.alert(err || "Something went wrong!");
          }
        });
      setcheckEmpty(false);
    }
  };

  return (
    <>
      <CmAlert message={message} status={status} showAlert={visibleMessage} />
      {formStep === 1 && (
        <div className="forget-password-wrap">
          <div className="forget-password-main-wrap">
            <div className="forget-password-inner">
              <Loading showLoading={isLoading} />
              <div className="logo section">
                <div className="logo">
                  <img src={whiteBg} alt="Bellstone" />
                </div>
              </div>
              <div className="otp-content">
                <h3>Reset your password</h3>
                <p>
                  Please enter the email ID you used to created your Du Afrik
                  account. We will send you a OTP(one time password) via email.
                  {/* <span className="otp-email">{`( ${userEmail} )`}</span> */}
                </p>
              </div>
              <div className={`otp-form ${checkEmpty === true ? "error" : ""}`}>
                <input
                  type="text"
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    setcheckEmpty(false);
                  }}
                  id="otp"
                  name="otp"
                />
                {checkEmpty && <small className="error-req">*Required</small>}
              </div>
              {userEmail !== undefined && (
                <div className="otp-btn animate-pop-in">
                  <button
                    onClick={() => handleSubmit()}
                    className="button"
                    type="button"
                  >
                    Send reset code
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {formStep === 2 && (
        <div className="forget-password-wrap verify-otp-warp">
          <div className="forget-password-main-wrap">
            <div className="forget-password-inner">
              <Loading showLoading={isLoading} />
              <div className="logo section">
                <div className="logo">
                  <img src={whiteBg} alt="Bellstone" />
                </div>
              </div>
              <div className="otp-content">
                <h3>Verification required</h3>
                <p>
                  {` To continue, complete this verification step. We've sent an OTP to the email (${userEmail}). Please enter it below to complete verification`}
                  .
                  {/* <span className="otp-email">{`( ${userEmail} )`}</span> */}
                </p>
              </div>
              <div className={`otp-form ${checkEmpty === true ? "" : ""}`}>
                <input
                  className="mb-3"
                  type="text"
                  required
                  onChange={(e) => {
                    setOTP(e.target.value);
                    setcheckEmpty(false);
                  }}
                  id="otp"
                  name="otp"
                  placeholder="Enter OTP(one time password)"
                />
                <input
                  className="mb-3"
                  required
                  type="text"
                  onChange={(e) => {
                    setpassword(e.target.value);
                    setcheckEmpty(false);
                  }}
                  id="password"
                  name="password"
                  placeholder="Enter new password"
                />
                <input
                  required
                  type="text"
                  onChange={(e) => {
                    setCpassword(e.target.value);
                    setcheckEmpty(false);
                  }}
                  id="cpassword"
                  name="cpassword"
                  placeholder="Enter confirm password"
                />
                {checkEmpty && (
                  <small className="error-req">*All fields is Required</small>
                )}
              </div>
              {userEmail !== undefined && (
                <div className="otp-btn animate-pop-in">
                  <button
                    onClick={() => handleVerifyOTP()}
                    className="button"
                    type="button"
                  >
                    Reset password
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {formStep === 3 && (
        <div className="forget-password-wrap reset-password-warp">
          <div className="forget-password-main-wrap">
            <div className="forget-password-inner">
              <Loading showLoading={isLoading} />
              <div className="logo section">
                <div className="logo">
                  <img src={whiteBg} alt="Bellstone" />
                </div>
              </div>
              <div className="otp-content">
                <h3>Reset your password</h3>
                <p>
                  Please enter the email ID you used to created your Du Afrik
                  account. We will send you a OTP(one time password) via email.
                  {/* <span className="otp-email">{`( ${userEmail} )`}</span> */}
                </p>
              </div>
              <div className={`otp-form ${checkEmpty === true ? "error" : ""}`}>
                <input
                  type="text"
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    setcheckEmpty(false);
                  }}
                  id="otp"
                  name="otp"
                />
                {checkEmpty && <small className="error-req">*Required</small>}
              </div>
              {userEmail !== undefined && (
                <div className="otp-btn animate-pop-in">
                  <button
                    onClick={() => handleSubmit()}
                    className="button"
                    type="button"
                  >
                    Send reset code
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgetPassword;
