import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import BlueLogo from "../../assets/img/blue-logo.png";
import transparentWhiteLogo from "../../assets/img/whiteLogo.png";
import cartImgIcon from "../../assets/img/cart.png";
import { MdAppRegistration } from "react-icons/md";
import {
  FaMinus,
  FaPlus,
  FaRegUser,
  FaSearch,
  FaTimes,
  FaTrash,
  FaBars,
  FaTrashAlt,
  FaShoppingCart,
  FaSignOutAlt,
  FaUserAlt,
} from "react-icons/fa";
import "./Header.scss";
import { IoMdSearch } from "react-icons/io";
import { useLocation } from "react-router";
import Product from "../../assets/img/718AvLsvnZL._MCnd_AC_UL320_.png";
import { useSelector } from "react-redux";
import {
  DELETE_CART_ITEM,
  GET_HOME_DATA,
  GET_PRODUCT_LIST_FOR_USER,
  globalAction,
  LOGOUT,
  SET_CURRENT_COUNTRY,
  SHOW_LOADING,
  UPDATE_CART_ITEM_QTY,
} from "../../redux";
import { useDispatch } from "react-redux";
import CmAlert from "../CmAlert/CmAlert";
import Loading from "../Loading/Loading";
import AllCountries from "../../Utilis/allCountry.json";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { headerApi } from "../../api";
import SeoSetting from "../SeoSetting/SeoSetting";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function Header() {
  let location = useLocation();
  let urlPath = location.pathname;
  const param = useParams();
  const [showCart, setShowCart] = useState(false);
  const { user } = useSelector((state) => state.authReducer);
  const { homeData } = useSelector((state) => state.appReducer);
  const { message, status, visibleMessage, isLoading } = useSelector(
    (state) => state.errorReducer
  );
  const { cartList, cartTotal, currentCountry } = useSelector(
    (state) => state.appReducer
  );
  const dispatch = useDispatch();
  const getQty = useRef();
  const menuItemRef = useRef();
  const params = new URL(document.location).searchParams;
  const countryID = params.get("country_id");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [hoverMenuItem, setHoverMenuItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    country_id: 0,
    limit: 24,
  });
  // const [currentCounrty, setCurrentCounrty] = useState(undefined);
  const [currentCountryInfo, setCurrentCountryInfo] = useState("");
  const [headerData, setHeaderData] = useState(undefined);
  const [searchMenu, setSearchMenu] = useState(false);
  let history = useHistory();

  let filterObj = searchQuery || {
    country_id: currentCountry.id || 0,
    order: "desc",
  };
  useEffect(() => {
    setSearchMenu(false);
  }, [window.location.pathname]);

  //
  const fetchCurrentCountry = async () => {
    await axios
      .get("https://ipapi.co/json/")
      .then(async (res) => {
        // await setCurrentCounrty(res.data.country_name);
        let tempSelectCountry = AllCountries.find(
          (countryItem) =>
            countryItem?.name.toLowerCase() ===
            res.data.country_name.toLowerCase()
        );
        dispatch(globalAction(SET_CURRENT_COUNTRY, tempSelectCountry));
        setCurrentCountryInfo(tempSelectCountry);
        setSearchQuery({ ...searchQuery, country_id: tempSelectCountry?.id });
        return res.data.country;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    dispatch(globalAction(GET_HOME_DATA));
    let tempSelectCountry;
    if (currentCountry === undefined) {
      fetchCurrentCountry();
    }
    if (currentCountry) {
      tempSelectCountry = AllCountries.find(
        (countryItem) =>
          countryItem?.name.toLowerCase() === currentCountry.name.toLowerCase()
      );
    }
    dispatch(globalAction(SET_CURRENT_COUNTRY, tempSelectCountry));
    setCurrentCountryInfo(tempSelectCountry);
    setSearchQuery({ ...searchQuery, country_id: tempSelectCountry?.id });
    // getHeader Data
    headerApi()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setHeaderData(data.header);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentCountry]);

  const cartShow = (e) => {
    e.preventDefault();
    setShowCart(!showCart);
  };

  const cartHide = () => {
    setShowCart(false);
  };

  const mobileOpen = () => {
    setMobileMenu(!mobileMenu);
  };

  const closeMenu = () => {
    setMobileMenu(false);
    localStorage.removeItem("sQuery");
  };

  window.addEventListener("scroll", () => {
    setSearchMenu(false);
    if (window.scrollY > 5) {
      document.getElementsByTagName("body")[0].classList.add("scroll");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("scroll");
    }
  });

  useEffect(() => {}, [cartList, currentCountry]);

  const handleChangeQty = (e, typeOfChange, i, stock_qty) => {
    dispatch(globalAction(SHOW_LOADING));
    let tempQty = Number(getQty.current.innerText);
    if (typeOfChange === "minus" && tempQty > 0) {
      tempQty = cartList[i]["qty"] - 1;
      cartList[i]["qty"] = tempQty;
    } else {
      tempQty = cartList[i]["qty"] + 1;
      if (tempQty <= stock_qty) {
        cartList[i]["qty"] = tempQty;
      }
    }
    dispatch(
      globalAction(UPDATE_CART_ITEM_QTY, { cartList: cartList, user: user })
    );
  };

  const handleDelete = (e, Item, i) => {
    dispatch(globalAction(SHOW_LOADING));
    cartList.splice(i, 1);
    dispatch(
      globalAction(DELETE_CART_ITEM, {
        cartList: cartList,
        item: Item,
        user: user,
        showMessage: true,
      })
    );
  };

  const handleChange = (value, fieldName) => {
    setSearchQuery({ ...searchQuery, [fieldName]: value });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let sQuery = {
      search: searchQuery?.search,
      country_id: searchQuery?.country_id,
      category_slug: searchQuery?.category_slug,
      limit: 24,
    };
    if (sQuery.category_slug == 1) {
      // handle search when user select all categories
      delete sQuery["category_slug"];
    }
    if (sQuery.country_id == 0) {
      // handle search when user select all country
      delete sQuery["country_id"];
    }
    localStorage.setItem("sQuery", JSON.stringify(sQuery));
    dispatch(
      globalAction(GET_PRODUCT_LIST_FOR_USER, {
        filterObj: sQuery,
        history: history,
      })
    );
  };

  const handleCategoryRedirection = (catSlug) => {
    filterObj = JSON.parse(localStorage.getItem("filterObj")) || [];
    filterObj["category_slug"] = catSlug;
    dispatch(
      globalAction(GET_PRODUCT_LIST_FOR_USER, {
        filterObj: filterObj,
        history: history,
      })
    );
  };

  const getSubCategoryData = (e, filterValue, filterName) => {
    e.preventDefault();
    filterObj["page"] = 0;
    localStorage.removeItem("brand_id");
    delete filterObj["brand_id"];
    filterObj = JSON.parse(localStorage.getItem("filterObj"));
    filterObj["sub_category_slug"] = filterValue;
    delete filterObj["search"];
    if (filterName === "sub_category_slug") {
      delete filterObj["category_slug"];
    }
    if (filterName === "category_slug") {
      delete filterObj["sub_category_slug"];
    }
    dispatch(globalAction(SHOW_LOADING));
    dispatch(
      globalAction(GET_PRODUCT_LIST_FOR_USER, {
        filterObj: filterObj,
        history: history,
      })
    );
  };
  return (
    <>
      <SeoSetting
        title="Du-Afrik-Dashboard"
        metaDescription="Dashboard of Du-Afrik"
      />
      <CmAlert message={message} status={status} showAlert={visibleMessage} />
      {/* <div className={`header-main-wrap ${mobileMenu ? "mobile_open" : ""} ${urlPath === "/" || urlPath === "/home" || urlPath === "/products" || urlPath === "/products/" ? " h-transparent" : "no-banner"}`}> */}

      <div
        className={`header-main-wrap ${mobileMenu ? "mobile_open" : ""} ${
          urlPath === "/" ||
          urlPath === "/home" ||
          urlPath === "/products" ||
          urlPath === "/products/*" ||
          urlPath === "/products/" ||
          urlPath === "/products/?*" ||
          urlPath === "/sell-with-us"
            ? " h-transparent"
            : "no-banner"
        }`}
      >
        <div className="header-inner clearfix">
          <div className="logo-wrap">
            <Link
              to="/"
              onClick={() => {
                closeMenu();
                setSearchMenu(false);
              }}
            >
              <img src={headerData?.logo} alt="Du-Afric" />
            </Link>
          </div>
          <div className="desktop-menu-wrap">
            <div className="search-wrap">
              <div
                className="menu-wrap"
                //
              >
                <ul
                  className="level-1"
                  onMouseLeave={() => setHoverMenuItem(null)}
                >
                  {homeData?.category?.map((item, i) => {
                    if (i < 6) {
                      return (
                        <li
                          className="menu-item"
                          onMouseLeave={() => setHoverMenuItem(null)}
                        >
                          <NavLink
                            to={`/products/${item?.slug}`}
                            className={` menu-link ${
                              hoverMenuItem === i ? "active-class" : ""
                            }`}
                            onClick={() => {
                              handleCategoryRedirection(item?.slug);
                              closeMenu();
                            }}
                            onMouseEnter={() => (
                              setHoverMenuItem(i), setSearchMenu(false)
                            )}
                            // onMouseLeave={() => setHoverMenuItem(null)}
                          >
                            {item.name}
                          </NavLink>
                          {i === hoverMenuItem && (
                            <div className="mega-submenu">
                              <div className="transparent-div"></div>
                              <ul className="submenu-ul">
                                {item?.sub_category.length > 0 &&
                                  item?.sub_category.map(
                                    (sub, index) =>
                                      i === hoverMenuItem && (
                                        <>
                                          <li
                                            key={index}
                                            onClick={(e) =>
                                              getSubCategoryData(
                                                e,
                                                sub?.slug,
                                                "sub_category_slug"
                                              )
                                            }
                                          >
                                            {sub?.name}
                                          </li>
                                        </>
                                      )
                                  )}
                              </ul>
                            </div>
                          )}
                        </li>
                      );
                    }
                  })}
                  <li className="menu-item">
                    <NavLink
                      to={`/all-categories`}
                      className="menu-link"
                      onClick={() => {
                        // handleCategoryRedirection(item?.slug)
                        closeMenu();
                      }}
                      onMouseEnter={() => setSearchMenu(false)}
                    >
                      All Categories
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="left-col-wrap">
            <div className="login left-menu-item">
              <div className="lm-inner">
                <div className="search-bar-icon">
                  <Link
                    to="#"
                    onClick={() => {
                      setSearchMenu(!searchMenu);
                    }}
                  >
                    <IoMdSearch size={20} />
                    <span>Search</span>
                  </Link>
                </div>
                {user ? (
                  <Link
                    to={`${
                      user.role_id == "3" ? "/vendorDashboard" : "/Dashboard"
                    }`}
                    className="userName"
                  >
                    <FaRegUser />
                    <span>{user?.name}</span>
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    <FaUserAlt size={15} />
                    <span>Login</span>
                  </Link>
                )}
              </div>
            </div>
            <div
              className="register left-menu-item"
              onClick={() => setSearchMenu(false)}
            >
              <div className="lm-inner">
                {user ? (
                  <Link
                    to="/login"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(globalAction(LOGOUT));
                      mobileOpen();
                    }}
                    className="logout"
                  >
                    <FaSignOutAlt />
                    <span>Logout</span>
                  </Link>
                ) : (
                  <Link to="/sign-up">
                    <MdAppRegistration size={18} />
                    <span>Register</span>
                  </Link>
                )}
              </div>
            </div>
            {cartList.length > 0 && (
              <div className="cart-icon left-menu-item">
                <div className="lm-inner">
                  <Link
                    to="/register"
                    onClick={(e) => {
                      cartShow(e);
                      closeMenu();
                    }}
                  >
                    <img src={cartImgIcon} alt="Go to cart" />
                    <span className="cart_count">{cartList.length}</span>
                  </Link>
                </div>
              </div>
            )}
            {cartList.length > 0 && (
              <div className={`shipping_cart ${showCart ? "show" : ""}`}>
                <Loading showLoading={isLoading} />
                <div className="shipping_cart_inner">
                  <div className="cart_ttile">
                    <h4>Recently Added {cartList.length} Item(s)</h4>
                    <span
                      onClick={(e) => {
                        cartShow(e);
                      }}
                    >
                      <FaTimes />
                    </span>
                  </div>
                  {cartList.map((item, i) => {
                    const {
                      id,
                      created_at,
                      image_url,
                      title,
                      stock_qty,
                      regular_price,
                      sale_price,
                      qty,
                      slug,
                    } = item;
                    return (
                      <div key={created_at} className="cart_item">
                        <div className="image">
                          <Link to={`/product-detail/${slug}`}>
                            <img src={image_url} alt={title} />
                          </Link>
                        </div>

                        <div className="discription">
                          <Link to={`/product-detail/${slug}`}>
                            <h5>{title}</h5>
                          </Link>
                          {sale_price !== null ? (
                            <>
                              <del>{regular_price}$</del>
                              <span className="price">{sale_price}$</span>
                              <span className="qty">per QTY</span>
                            </>
                          ) : (
                            <>
                              <span className="price">{regular_price}$</span>
                              <span className="qty">per QTY</span>
                            </>
                          )}

                          <div className="qty">
                            {qty > 1 && (
                              <span
                                className="minu"
                                onClick={(e) =>
                                  handleChangeQty(e, "minus", i, stock_qty)
                                }
                              >
                                <FaMinus />
                              </span>
                            )}
                            <span className="num" ref={getQty}>
                              {qty}
                            </span>
                            <span
                              className="plus"
                              onClick={(e) =>
                                handleChangeQty(e, "plus", i, stock_qty)
                              }
                            >
                              <FaPlus />
                            </span>
                          </div>
                        </div>
                        <div
                          className="delet"
                          onClick={(e) => {
                            handleDelete(e, item, i);
                          }}
                        >
                          <span>
                            <FaTrash />
                          </span>
                        </div>
                      </div>
                    );
                  })}

                  <div className="button_wrap">
                    <div className="grand_total">
                      <h6>Grand Total</h6>
                      <span>{cartTotal}$</span>
                    </div>
                    <Link
                      to="/shopping-cart"
                      onClick={() => {
                        cartHide();
                        localStorage.removeItem("buyNow");
                      }}
                    >
                      View Cart
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <div className="mobile_icon" onClick={() => mobileOpen()}>
              <span className="open">
                <FaBars />
              </span>
              <span className="close">
                <FaTimes />
              </span>
            </div>
          </div>
          {searchMenu && (
            <div className="serach-bar-menu">
              <form className="clearfix" onSubmit={(e) => handleSearch(e)}>
                <div className="select-input">
                  <select
                    className="h-s-country input"
                    onChange={(e) => handleChange(e.target.value, "country_id")}
                  >
                    {AllCountries.map((item, i) => {
                      return (
                        <option value={item.id} selected={item.id == countryID}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="select-input">
                  <select
                    className="h-s-catgory input"
                    onChange={(e) =>
                      handleChange(e.target.value, "category_slug")
                    }
                  >
                    <option value="0">All Categories</option>
                    {homeData?.category?.map((item, i) => {
                      return <option value={item?.slug}>{item?.name}</option>;
                    })}
                  </select>
                </div>

                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search here..."
                    onChange={(e) => {
                      handleChange(e.target.value, "search");
                    }}
                  />
                  <button type="submit" className="cm-button">
                    <FaSearch size={15} />
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
