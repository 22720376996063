import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getProductAttributeApi } from '../../../../../api';
import { ADD_NEW_PRODUCT_BY_VENDOR, ADD_NEW_PRODUCT_BY_VENDOR_WITH_VARIANT, ADD_PRODUCT_VARIANT_BY_VENDOR, globalAction, SHOW_LOADING, UPDATE_PRODUCT_VARIANT_BY_ID } from '../../../../../redux';
import './AddProductVariant'
import './AddProductVariant.scss'
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { TiDelete } from 'react-icons/ti';
import { RiDeleteBin2Line } from 'react-icons/ri';
import ImgPreview from "../../../../../assets/upload_image_sample.png";


function AddProductVariant(props) {
    const dispatch = useDispatch()
    const { modalFlag, handleClose, btnText, modalHeading, productId, userId, userForUpdate, variantData, productFormData, addVariantOnExitProduct } = props
    const [fromData, setfromData] = useState({
        user_id: userId,
        same_price: 'yes',
        gallery_images_variant: [],
        galleryImgPreview: [],
        stock_availability: "in_stock",
        gallery_images: [],
        galleryImgPreview: [],
    })
    const [attributeList, setattributeList] = useState([])
    const [attributeCount, setAttributeCount] = useState([1])



    const changeInputHandle = (value, fieldName) => {
        setfromData({ ...fromData, [fieldName]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(globalAction(SHOW_LOADING))

        if (localStorage.getItem('rpId') === null) {
            console.log('Orange')
            dispatch(globalAction(SHOW_LOADING))
            dispatch(
                globalAction(ADD_NEW_PRODUCT_BY_VENDOR_WITH_VARIANT, {
                    productFormData: { ...productFormData, 'user_id': userId },
                    variantData: fromData
                })
            );
        }
        if(localStorage.getItem('rpId')) {
            console.log('Apple')
            let finalObj = { ...fromData, product_id: localStorage.getItem('rpId'), user_id: userId }
            delete finalObj['photo_variantPreview']
            delete finalObj['galleryImgPreview']
            dispatch(globalAction(ADD_PRODUCT_VARIANT_BY_VENDOR, finalObj))
            setfromData({ // reset state
                same_price: 'yes',
                gallery_images_variant: [],
                galleryImgPreview: [],
                stock_availability: "in_stock",
                gallery_images: [],
                galleryImgPreview: [],
            })
        }
        handleClose()
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        console.log('handle Update')
        dispatch(globalAction(SHOW_LOADING))
        if (addVariantOnExitProduct && productId) {
            let finalObj = { ...fromData, 'product_id': productId, 'user_id': userId, }
            delete finalObj['photo_variantPreview']
            delete finalObj['galleryImgPreview']
            dispatch(globalAction(ADD_PRODUCT_VARIANT_BY_VENDOR, finalObj))
        }
        if (userForUpdate && productId) {
            let finalObj = { ...fromData, 'product_id': productId, 'user_id': userId, }
            delete finalObj.tableData
            delete finalObj.product
            delete finalObj.attribute
            delete finalObj['galleryImgPreview']
            delete finalObj.image
            delete finalObj.product_variant_images
            dispatch(globalAction(UPDATE_PRODUCT_VARIANT_BY_ID, finalObj))
        }
        setfromData({ // reset state
            same_price: 'yes',
            gallery_images_variant: [],
            galleryImgPreview: [],
            stock_availability: "in_stock",
            gallery_images: [],
            galleryImgPreview: [],
        })

        handleClose()
    }



    const onSelectImage = async (value, fieldName) => {
        let allfile = await value.target.files;
        let galleryImgPreview = fromData.galleryImgPreview;
        let gallery_images_variant = fromData.gallery_images_variant;
        // let reader =  new FileReader();
        if (allfile) {
            Object.keys(allfile).forEach((i) => {
                if (i <= 5) {
                    const file = allfile[i];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        if (fieldName === "photo_variant") {
                            setfromData({
                                ...fromData,
                                [fieldName]: file,
                                [fieldName + "Preview"]: reader.result,
                            });
                        }

                        gallery_images_variant.push(file);
                        galleryImgPreview.push(reader.result);
                    };

                    reader.readAsDataURL(file);
                    reader.onloadend = (e) => {
                        if (fieldName === "gallery_images_variant") {

                            setfromData({
                                ...fromData,
                                ["gallery_images_variant"]: gallery_images_variant,
                                ["galleryImgPreview"]: galleryImgPreview,
                            });
                        }
                    };
                }
            });
        }
    };

    const handleGalleryImages = function (itemIndex, galleryArray) {
        fromData.galleryImgPreview.splice(itemIndex, 1)
        fromData?.gallery_images_variant.splice(itemIndex, 1)
        setfromData({ ...fromData })

    }

    const getAttributes = async () => {
        await getProductAttributeApi()
            .then(async (res) => {
                const { data, status } = res;
                if (status === 200) {
                    await setattributeList(data.attributes)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }



    useEffect(() => {
        getAttributes()
        if (userForUpdate && variantData) {
            let galleryImagesVariant=[]
            variantData?.product_variant_images.forEach(async (item) => {
                // console.log(item.image.substring(item.image.lastIndexOf(".")))
                const response = await fetch(item.image);
                const data = await response.blob();
                let fileObj= new File([data], item.image, {
                    type: response.headers.get('content-type') || 'image/jpeg',
                });
                galleryImagesVariant.push(fileObj)
            })
            setfromData({
                ...variantData,
                galleryImgPreview: variantData?.product_variant_images.filter((item) => item.image),
                gallery_images_variant: galleryImagesVariant
            })
        }

        

       

    }, [userForUpdate])


    console.log('fromData', fromData)
    return (
        <div className="addProduct-variant-wrap">
            <div className="apv-inner">
                <Modal
                    className="apv-modal"
                    show={modalFlag}
                    onHide={handleClose}
                // fullscreen={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{modalHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalFro">
                            <Form
                                className='form'
                                onSubmit={(e) => { (addVariantOnExitProduct || userForUpdate) ? handleUpdate(e) : handleSubmit(e) }}>
                                {attributeCount.map((item, i) => {
                                    return (
                                        <>
                                            <Row className="mt-4">
                                                <Form.Group as={Col} controlId="variantType">
                                                    <Form.Label>Variant Type</Form.Label>
                                                    <Form.Select
                                                        className='input'
                                                        required
                                                        aria-label="Default Variant Type "
                                                        onChange={(e) => { changeInputHandle(e.target.value, `${i > 0 ? `attribute_id_${1 + i}` : 'attribute_id'}`) }}
                                                    >
                                                        <option>Select Variant Type</option>
                                                        {attributeList.length > 0 &&
                                                            attributeList.map((item, i) => {
                                                                return (
                                                                    <option
                                                                        selected={item.id === (i > 0 ? fromData[`attribute_id_${1 + i}`] : fromData['attribute_id'])} value={item.id}>{item.name}</option>
                                                                )
                                                            })}
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="variantName">
                                                    <Form.Label>Variant Name</Form.Label>
                                                    <Form.Control
                                                        className='input'
                                                        type="text"
                                                        placeholder="Enter variant name"
                                                        value={i > 0 ? fromData[`variant_name_${1 + i}` || ''] : fromData['variant_name']}
                                                        required
                                                        onChange={(e) => { changeInputHandle(e.target.value, `${i > 0 ? `variant_name_${1 + i}` : 'variant_name'}`) }}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            {i > 0 &&
                                                <div className="add-v-attri delete-icon">
                                                    <p>Delete this variant attribute ..?
                                                        <span
                                                            onClick={(e) => {
                                                                attributeCount.splice(i, 1);
                                                                if (fromData[`variant_name_${2 + i}`]) {
                                                                    fromData[`variant_name_${1 + i}`] = fromData[`variant_name_${2 + i}`]
                                                                    fromData[`attribute_id_${1 + i}`] = fromData[`attribute_id_${2 + i}`]
                                                                    delete fromData[`variant_name_${2 + i}`]
                                                                    delete fromData[`attribute_id_${2 + i}`]
                                                                }
                                                                else {
                                                                    delete fromData[`variant_name_${1 + i}`]
                                                                    delete fromData[`attribute_id_${1 + i}`]
                                                                }
                                                                setfromData({ ...fromData })
                                                                setAttributeCount([...attributeCount])
                                                            }}
                                                        ><RiDeleteBin2Line /></span></p>
                                                </div>
                                            }
                                        </>


                                    )
                                })}

                                {attributeCount.length < 3 &&
                                    <div className="add-v-attri">
                                        <p>Do you want more variant attribute.?
                                            <span
                                                onClick={() => setAttributeCount([...attributeCount, (attributeCount[attributeCount.length - 1] + 1)])}
                                            ><AiOutlinePlusSquare /></span></p>
                                    </div>
                                }
                                <Row className="mt-4">
                                    <Form.Group as={Col} controlId="stockStatus">
                                        <Form.Label>
                                            Stock Status <span className="requered"> *</span>
                                        </Form.Label>
                                        <Form.Select
                                            aria-label="Default checkStockOptions"
                                            onChange={(e) =>
                                                changeInputHandle(e.target.value, "stock_availability")
                                            }
                                        >
                                            <option
                                                value="in_stock">
                                                In Stock
                                            </option>
                                            <option
                                                value="out_stock">Out Of Stock</option>
                                        </Form.Select>
                                    </Form.Group>
                                    {fromData?.stock_availability === 'in_stock' &&
                                        <Form.Group as={Col} controlId="salePrice">
                                            <Form.Label>
                                                Stock QTY<span className="requered"> *</span>
                                            </Form.Label>
                                            <Form.Control
                                                className="input"
                                                type="text"
                                                defaultValue={fromData?.stock_qty}
                                                placeholder="Enter product stock quantity price"
                                                onChange={(e) =>
                                                    changeInputHandle(e.target.value, "stock_qty")
                                                }
                                            />
                                        </Form.Group>
                                    }

                                </Row>

                                <Form.Group className="mt-4" controlId="variantType">
                                    <Form.Label>Price Same As Product ?</Form.Label>
                                    <Form.Select
                                        className='input'
                                        required
                                        aria-label="Default Variant Type "
                                        defaultValue={fromData?.same_price}
                                        onChange={(e) => { changeInputHandle(e.target.value, 'same_price') }}

                                    >
                                        <option>Select Variant Type</option>
                                        <option selected={'yes' === fromData?.same_price} value="yes">Yes</option>
                                        <option selected={'no' === fromData?.same_price} value="no">No</option>
                                    </Form.Select>
                                </Form.Group>
                                {fromData.same_price === 'no' &&
                                    <Form.Group className="mt-4" controlId="variantRegularPrice">
                                        <Form.Label>Regular Price</Form.Label>
                                        <Form.Control
                                            className='input'
                                            type="number"
                                            placeholder="Enter regular price"
                                            required
                                            defaultValue={fromData?.regular_price}
                                            onChange={(e) => { changeInputHandle(e.target.value, 'regular_price_new') }}
                                        />
                                    </Form.Group>
                                }

                                {fromData.same_price === 'no' &&
                                    <Form.Group className="mt-4" controlId="variantSalePrice">
                                        <Form.Label>Sale Price</Form.Label>
                                        <Form.Control
                                            className='input'
                                            type="number"
                                            placeholder="Enter sale price"
                                            required
                                            defaultValue={fromData?.sale_price}
                                            onChange={(e) => { changeInputHandle(e.target.value, 'sale_price_new') }}
                                        />
                                    </Form.Group>
                                }

                                <Row className="mt-4 product-image">
                                    <Col>
                                        <Form.Group
                                            controlId="productMainImage"
                                            className="mb-3 cm-file-upload"
                                        >
                                            <Form.Label>
                                                Image <span className="requered">*</span>
                                            </Form.Label>
                                            <div className="previewImage">
                                                <img
                                                    src={fromData.image || fromData.photo_variantPreview || ImgPreview}
                                                    alt="Product Main Image" />
                                            </div>
                                            <Form.Control
                                                accept="image/*"
                                                type="file"
                                                className="input "
                                                onChange={(e) => onSelectImage(e, "photo_variant")}
                                            />
                                            <Form.Text id="product_main_img" muted>
                                                (file will be in png/jpeg format)
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-4 productGalleries">
                                    <Col>
                                        <Form.Group
                                            controlId="productGalleries"
                                            className="mb-3 cm-file-upload"
                                        >
                                            <Form.Label>Product Gallery Images</Form.Label>
                                            <div className="upload-input-wrap">
                                                <div className="gallery-img-preview">
                                                    {fromData?.galleryImgPreview?.length > 0 &&
                                                        fromData?.galleryImgPreview?.map((item, i, itemArr) => {
                                                            return (
                                                                <div className="gip-img" key={`gip-img-${i}`}>
                                                                    <img src={item.image || item} alt="Preview" />
                                                                    {item &&
                                                                        <div className="delete-img"
                                                                            onClick={(e) => {
                                                                                handleGalleryImages(i)
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <TiDelete />
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                </div>

                                                            );
                                                        })}
                                                </div>
                                                <div className="upload-input-inner">
                                                    <Form.Control
                                                        accept="image/*"
                                                        type="file"
                                                        className="input pg-image "
                                                        multiple
                                                        onChange={(e) => onSelectImage(e, "gallery_images_variant")}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className="mt-4 mb-5" controlId="variantSalePrice">
                                    <Form.Label>Short Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        className='input'
                                        placeholder="Short Description"
                                        style={{ height: '125px' }}
                                        defaultValue={fromData?.short_desc}
                                        onChange={(e) => { changeInputHandle(e.target.value, 'short_desc') }}

                                    />
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className='btn-brown'
                                >
                                    Submit
                                </Button>
                            </Form>
                        </div>

                    </Modal.Body>

                </Modal>
            </div>
        </div >
    )
}

export default AddProductVariant
