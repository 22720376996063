import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  aboutUsApi,
  affiliateApi,
  inNewsApi,
  privacyPolicyApi,
  termsConditionApi,
  vendorConnectApi,
  walletTcApi,
} from "../../api";
import Loading from "../../components/Loading/Loading";
import SeoSetting from "../../components/SeoSetting/SeoSetting";
import { CLOSE_LOADING, globalAction, SHOW_LOADING } from "../../redux";
import "./StaticPages.scss";
import parse from "html-react-parser";

function AboutUs(props) {
  const {
    match: { path },
  } = props;
  const [pageData, setPageData] = useState(undefined);
  const { isLoading } = useSelector((state) => state.errorReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scroll({ top: 0 });
    dispatch(globalAction(SHOW_LOADING));
    if (path === "/about-us") {
      aboutUsApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.about_us);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else if (path === "/faqs") {
    } else if (path === "/privacy-policy") {
      privacyPolicyApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.privacy_policy);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else if (path === "/Terms-and-Condtions") {
      termsConditionApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.terms_condition);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else if (path === "/affiliate") {
      affiliateApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.affilate);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else if (path === "/wallet-terms-and-conditions") {
      walletTcApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.wallet_tc);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else if (path === "/vendor_connect") {
      vendorConnectApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.vendor_connect);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else if (path === "/in-news") {
      inNewsApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.in_news);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else if (path === "/seller-policy") {
      inNewsApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.in_news);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else if (path === "/delivery-policyy") {
      inNewsApi()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            dispatch(globalAction(CLOSE_LOADING));
            setPageData(data.in_news);
          }
        })
        .catch(() => {
          dispatch(globalAction(CLOSE_LOADING));
        });
    } else {
      dispatch(globalAction(CLOSE_LOADING));
      setPageData(undefined);
    }
    return () => {
      setPageData(undefined);
    };
  }, [path]);

  return (
    <div className="static-pages">
      <div className="container">
        <div className="container-inner">
          <Loading showLoading={isLoading} />
          <SeoSetting
            title={pageData?.title}
            metaDescription={pageData?.title}
          />
          {pageData?.title && (
            <div className="heading-section">
              <h1>{pageData?.title}</h1>
            </div>
          )}
          <div className="content-body">
            <div className="content-1">
              {parse(pageData?.description || "")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
