import React, { useEffect } from "react";
import "./OrderManagement.scss";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import MaterialTable from "material-table";
import tableIcons from "../TableIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_REVIEW_BY_USER,
  CANCEL_ORDER_PRODUCT,
  CLOSE_LOADING,
  GET_ORDER_LIST_BY_USER_ID,
  globalAction,
  SEND_ORDER_FEEDBACK_BY_USER,
  SHOW_LOADING,
} from "../../../redux";
import { getOrderListByUserIdApi } from "../../../api";
import imagePlaceholder from "../../../assets/img/productDemo.jpg";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";

function Ordermanagement() {
  const { user } = useSelector((state) => state.authReducer);
  const { message } = useSelector((state) => state.errorReducer);
  const [orderListData, setOrderListData] = useState(undefined);
  const [ModalFlag, setModalFlag] = useState(false);
  const [activeModalName, setActiveModalName] = useState(undefined);
  const [rating, setRating] = useState(undefined);
  const [review, setReview] = useState(undefined);
  const [formData, setFormDataInput] = useState({});
  const [orderItemInfo, setOrderItemInfo] = useState(undefined);

  const dispatch = useDispatch();
  let params;

  useEffect(() => {
    if (user) {
      params = `?id=${user?.id}&limit=3`;
      dispatch(globalAction(SHOW_LOADING));
      getOrderList(params);
    }
  }, []);

  useEffect(() => {
    if (message === "Order Product cancelled successfully. ") {
      let params = `?id=${user?.id}&limit=3&page=${orderListData?.current_page}`; // refresh current order list
      dispatch(globalAction(SHOW_LOADING));
      getOrderList(params);
    }
  }, [message]);

  const getOrderList = (params) => {
    getOrderListByUserIdApi(params)
      .then((res) => {
        dispatch(globalAction(CLOSE_LOADING));
        const { status, data } = res;
        if (status === 200) {
          setOrderListData(data.data);
        }
      })
      .catch((err) => {
        dispatch(globalAction(CLOSE_LOADING));
        alert("Something went wrong.Please try again");
      });
  };

  const handlePageClick = (data) => {
    params = `?id=${user?.id}&limit=3&page=${(data.selected += 1)}`;
    dispatch(globalAction(SHOW_LOADING));
    getOrderList(params);
  };

  const HandleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleChange = (value, fieldName) => {
    setFormDataInput({ ...formData, [fieldName]: value });
  };

  const handleSelectImg = (e, fieldName) => {
    setFormDataInput({ ...formData, [fieldName]: e.target.files[0] });
  };

  const modalSubmit = (e, formType) => {
    let finalObj;
    if (formType === "feedback" && rating !== undefined) {
      finalObj = {
        user_id: user?.id,
        order_id: orderItemInfo?.orderItemInfo?.order_id,
        product_id: orderItemInfo?.orderItemInfo?.id,
        rating: rating,
        photo: formData?.photo,
        message: formData?.message,
      };
      dispatch(globalAction(CLOSE_LOADING));
      dispatch(globalAction(SEND_ORDER_FEEDBACK_BY_USER, finalObj));
    } else if (formType === "writeReview" && rating !== undefined) {
      finalObj = {
        user_id: user?.id,
        order_id: orderItemInfo?.orderItemInfo?.order_id,
        product_id: orderItemInfo?.orderItemInfo?.product_id,
        rating: rating,
        photo: formData?.photo,
        review: formData?.review,
      };
      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(ADD_REVIEW_BY_USER, finalObj));
    } else {
      finalObj = {
        user_id: user?.id,
        order_id: orderItemInfo?.orderItemInfo?.order_id,
        order_product_id: orderItemInfo?.orderItemInfo?.id,
        cancellation_reason: formData?.cancellation_reason,
      };

      dispatch(globalAction(SHOW_LOADING));
      dispatch(globalAction(CANCEL_ORDER_PRODUCT, finalObj));
    }

    // reset all modal related states
    setActiveModalName(undefined);
    setModalFlag(false);
    setOrderItemInfo(undefined);
    setFormDataInput({});
    finalObj = {};
  };

  const CustomModal = () => {
    switch (activeModalName) {
      case "cancel-item":
        return (
          <div className="cancel-order ">
            <Modal
              show={ModalFlag}
              onHide={() => {
                setModalFlag(!ModalFlag);
              }}
              backdrop="static"
              keyboard={true}
              className="cancel-order-inner"
            >
              <Modal.Header closeButton>
                <Modal.Title>Cancel Order Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure to Cancel this (
                {orderItemInfo?.orderItemInfo?.product_title}) Item ?
                <div className="cancel-reason-wrap">
                  <label htmlFor="cancel-reason">
                    Cancellation Reason<span>(optional)</span>
                  </label>
                  <textarea
                    type="text"
                    rows="5"
                    className="input"
                    onChange={(e) => {
                      handleChange(e.target.value, "cancellation_reason");
                    }}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    setModalFlag(!setModalFlag);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    modalSubmit(e, "cancel-item");
                  }}
                >
                  Cancel Item
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );

      case "order-feedback":
        return (
          <div className="cancel-order ">
            <Modal
              show={ModalFlag}
              onHide={() => {
                setModalFlag(!ModalFlag);
              }}
              backdrop="static"
              keyboard={true}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="cancel-order-inner order-feedback write-review"
            >
              <Modal.Header closeButton>
                <Modal.Title>Write Feedback </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Leave your valuable feedback against order to help us to improve
                our services.
                <div className="rating-star">
                  <ReactStars
                    count={5}
                    onChange={HandleRatingChange}
                    size={20}
                    isHalf={true}
                    edit={true}
                    activeColor="#ffd731"
                  />
                </div>
                <div className="cancel-reason-wrap">
                  <label htmlFor="cancel-reason">Write a feedback</label>
                  <textarea
                    type="text"
                    rows="5"
                    className="input"
                    onChange={(e) => {
                      handleChange(e.target.value, "message");
                    }}
                  />
                </div>
                <div className="cancel-reason-wrap">
                  <label htmlFor="attachment-reason">
                    Add attachment<span>its optional</span>
                  </label>
                  <input
                    type="file"
                    name=""
                    id="attachment-reason"
                    onChange={(e) => {
                      handleSelectImg(e, "photo");
                    }}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    setModalFlag(!setModalFlag);
                  }}
                >
                  Cancel
                </button>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    modalSubmit(e, "feedback");
                  }}
                >
                  Submit Feedback
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      default:
        return (
          <div className="cancel-order write-review">
            <Modal
              show={ModalFlag}
              onHide={() => {
                setModalFlag(!ModalFlag);
              }}
              backdrop="static"
              keyboard={true}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="cancel-order-inner write-review"
            >
              <Modal.Header closeButton>
                <Modal.Title>Write Product Reviews</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Leave your valuable review which helps our other customer.
                <div className="rating-star">
                  <ReactStars
                    count={5}
                    onChange={HandleRatingChange}
                    size={20}
                    isHalf={true}
                    edit={true}
                    activeColor="#ffd731"
                  />
                </div>
                <div className="cancel-reason-wrap">
                  <label htmlFor="review-reason">
                    Write a review<span>(optional)</span>
                  </label>
                  <textarea
                    type="text"
                    rows="5"
                    className="input"
                    id="review-reason"
                    onChange={(e) => {
                      handleChange(e.target.value, "review");
                    }}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    setModalFlag(!setModalFlag);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    modalSubmit(e, "writeReview");
                  }}
                >
                  Submit Review
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    }
  };

  const toggleModal = (flag, modalName, orderItemInfo) => {
    setActiveModalName(modalName);
    setModalFlag(flag);
    setOrderItemInfo(orderItemInfo);
  };

  return (
    <section className="order_management">
      <div className="page_title">
        <h3>Orders</h3>
      </div>
      {orderListData ? (
        <>
          {orderListData?.data?.map((item, i) => {
            const {
              order_at,
              order_price,
              shipping_addess_data,
              id,
              invoice_url,
              order_number,
              order_product,
              order_duration,
              order_status,
            } = item;
            return (
              <div className="order_wrap">
                <div className="order-inner">
                  <div className="top-section clearfix">
                    <div className="left">
                      <div className="inner">
                        <div className="order-place">
                          <p>ORDER PLACED</p>
                          <p>
                            <strong>{moment(order_at).format("LL")}</strong>
                          </p>
                        </div>
                        <div className="total">
                          <p>TOTAL</p>
                          <p>
                            <strong>{order_price}$</strong>
                          </p>
                        </div>
                        <div className="ship">
                          <p>SHIP TO</p>
                          <p>
                            <strong>{shipping_addess_data?.full_name}</strong>
                          </p>
                          <div className="Shipping-info">
                            <p>Rahul Lodhi</p>
                            <p>
                              7/1 Nand puri kanker Khera, Meerut Cantt, Uttar
                              Pradesh
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div className="inner">
                        <p>
                          ORDER #:<strong> {order_number}</strong>
                        </p>
                        <p>
                          <a href={`/dashboard/order-detail/${id}`}>
                            View order details{" "}
                          </a>
                          <span>|</span>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(`${invoice_url}`);
                            }}
                          >
                            Download Invoice
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="middle-section">
                    {order_product.map((item, i) => {
                      const {
                        id: orderItemId,
                        image_url,
                        product_title,
                        status,
                        refund_status,
                      } = item;
                      // status=1 means orderitem is active,
                      // status=2 means order item is cancel by user
                      // refund_status ==0 refund is pending
                      // refund_status ==1 refund is complete.
                      return (
                        <div className="middle-inner ">
                          <div className="inner clearfix">
                            <h5 className="estimate-day">
                              {moment()
                                .add(Number(order_duration), "days")
                                .format("LL")}
                            </h5>
                            <div className="left">
                              <div className="product-Info">
                                <div className="img">
                                  <a
                                    href={`/product-detail/${item?.product_slug}`}
                                  >
                                    <img
                                      src={image_url || imagePlaceholder}
                                      alt=""
                                    />
                                  </a>
                                </div>
                                <div className="title">
                                  <a
                                    href={`/product-detail/${item?.product_slug}`}
                                  >
                                    <h4>{product_title}</h4>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="right">
                              <div className="actions">
                                {/* <button className="btn-black">Track Details</button> */}
                                {refund_status === 0 && status === 1 && (
                                  <button
                                    className="btn-black refund-request"
                                    onClick={(e) => {
                                      toggleModal(!ModalFlag, "cancel-item", {
                                        orderItemInfo: item,
                                      });
                                    }}
                                  >
                                    Cancel Item
                                  </button>
                                )}

                                {order_status === "completed" ? (
                                  <button
                                    className="btn-black"
                                    onClick={(e) => {
                                      toggleModal(
                                        !ModalFlag,
                                        "order-feedback",
                                        { orderItemInfo: item }
                                      );
                                    }}
                                  >
                                    Write Order Feedback
                                  </button>
                                ) : (
                                  <Button
                                    className="btn-black --disabled"
                                    onClick={(e) => {
                                      alert(
                                        "This option is working after product is delivered. Thank you"
                                      );
                                    }}
                                  >
                                    Write Order Feedback
                                  </Button>
                                )}

                                {/* write product review */}
                                <button
                                  className="btn-black"
                                  onClick={(e) => {
                                    toggleModal(!ModalFlag, "review", {
                                      orderItemInfo: item,
                                    });
                                  }}
                                >
                                  Write Product Review
                                </button>
                              </div>
                            </div>
                          </div>
                          {refund_status === 0 && status === 2 && (
                            <p className="refund-note">
                              You cancelled this item from your order and{" "}
                              <span>Your refund request submitted.</span>. We
                              will refund your item amount in 7-8 working days.
                            </p>
                          )}
                          {refund_status === 1 && status === 2 && (
                            <p className="refund-note --success">
                              You cancelled this item from your order and{" "}
                              <span>Your refund request is completed.</span>
                            </p>
                          )}

                          {refund_status === 2 && status === 2 && (
                            <p className="refund-note --success">
                              You cancelled this item from your order and{" "}
                              <span>
                                Your refund request is reject by vendor.
                              </span>
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
          {orderListData?.data?.length > 0 ? (
            <div className="pagination-wrap">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  orderListData?.total / orderListData?.per_page
                )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={Number(orderListData?.current_page - 1)}
              />
            </div>
          ) : (
            <div className="no-product">
              <h3 style={{ color: "#ff7979" }}>No order found</h3>
            </div>
          )}
        </>
      ) : (
        <div className="no-order">
          <h4>No Order found...!</h4>
        </div>
      )}
      {CustomModal()} {/* Review/Cancel/Write review modal*/}
    </section>
  );
}

export default Ordermanagement;
