import React, { useState } from "react";
import "./Dashboard.scss";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { CHANGE_PASSWORD, globalAction, SHOW_LOADING } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

function ResetPassword() {
  const dispatch = useDispatch()
  const {user} = useSelector(state => state.authReducer)
  const [formData, setFormData] = useState(undefined)

 
  const handleSubmit = (e) => {
    e.preventDefault();
    //setFormData({ ...formData, id: user.id })
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(CHANGE_PASSWORD,formData));
    setFormData({id: user.id, new_password:'', old_password:'', confirm_password:''})
  }

  const handleChange = (value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  useEffect(() => {
    setFormData({  id: user.id })
  }, [user])


  return (
    <div className="user_dashboard reset_password">
      <div className="page_title">
        <h3>Reset Password</h3>
      </div>

      <div className="recent_order box-size">
        <Form
          onSubmit={(e) => handleSubmit(e)}
        >
          <Form.Group className="mb-3" controlId="currentPassowrd">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              className="input"
              type="password"
              value={formData?.old_password}
              placeholder="Current Password"
              onChange={(e) => { handleChange(e.target.value, 'old_password') }}
            />
          </Form.Group>

          <Form.Group 
          className="mb-3" controlId="NewPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              className="input"
              value={formData?.new_password}
              type="password"
              placeholder="New Password"
              onChange={(e) => { handleChange(e.target.value, 'new_password') }}
            />

          </Form.Group>

          <Form.Group
            className="mb-3" controlId="contfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              className="input"
              value={formData?.confirm_password}
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => { handleChange(e.target.value, 'confirm_password') }}
            />
          </Form.Group>
          <Button className="btn-black" variant="primary" type="submit">
            Change Passowrd
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
