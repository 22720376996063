import React, { useEffect } from "react";
import "./Dashboard.scss";
import Footer from "../../../DashboardComponents/Footer/Footer";
import Header from "../../../DashboardComponents/Header/Header";
import logo from "../../../assets/img/golden-logo.png";
import Menu from "../../../DashboardComponents/Menu/Menu";
import {
  IoIosKeypad,
  IoIosSettings,
  IoIosStats,
  IoMdCart,
  IoMdInformationCircleOutline,
  IoMdList,
  IoMdPerson,
} from "react-icons/io";
import {
  MdFavorite,
  MdLocalShipping,
  MdManageAccounts,
  MdSell,
} from "react-icons/md";
import { GoDash } from "react-icons/go";
import { ImCross } from "react-icons/im";
import DashboardRouter from "./DashboardRouter";
import Loading from "../../../components/Loading/Loading";
import CmAlert from "../../../components/CmAlert/CmAlert";
import { useSelector } from "react-redux";
import UserDashboardRoutes from "../../../pages/Dashboard/UserDashboardRoutes";
import { AiFillHome } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { GrDocumentUpdate } from "react-icons/gr";
import { walletTcApi } from "../../../api";
import { BsWallet2 } from "react-icons/bs";
import { GrTransaction } from "react-icons/gr";
import GetNotification from "./GetNotification";

const menuItems = [
  {
    key: 1,
    icon: <IoIosStats />,
    name: "Dashboard",
    url: "/vendorDashboard",
  },
  {
    key: 2,
    icon: <IoMdCart />,
    name: "Store ",
    url: "/vendorDashboard/store",
    childItems: [
      {
        icon: <IoMdCart />,
        name: "Store Profile",
        url: "/vendorDashboard/store",
      },
      {
        icon: <GrDocumentUpdate />,
        name: "Edit Company Info",
        url: "/vendorDashboard/update-company",
      },
      {
        icon: <GrDocumentUpdate />,
        name: "Edit Payment Info",
        url: "/vendorDashboard/update-payment-Info",
      },
      {
        icon: <MdSell />,
        name: "Selling Countries",
        url: "/vendorDashboard/selling-countries",
      },
      // {
      //   icon: <GrDocumentUpdate />,
      //   name: "Update KYC",
      //   url: "/vendorDashboard/update-KYC",
      // },
    ],
  },
  {
    key: 3,
    icon: <IoIosKeypad />,
    name: "Manage Products",
    url: "/vendorDashboard/product-management",
    childItems: [
      {
        icon: <IoIosKeypad />,
        name: "All Products",
        url: "/vendorDashboard/product-management",
      },
      {
        icon: <GoDash />,
        name: "Add Products",
        url: "/vendorDashboard/product-management/add-product",
      },
      {
        icon: <GoDash />,
        name: "Variant's Products",
        url: "/vendorDashboard/product-management/product-variants",
      },
    ],
  },
  {
    key: 4,
    icon: <MdLocalShipping />,
    name: "Order Management",
    url: "/vendorDashboard/order-management",
    childItems: [
      {
        icon: <MdLocalShipping />,
        name: "All Orders",
        url: "/vendorDashboard/order-management",
      },
      {
        icon: <GoDash />,
        name: "Active Orders",
        url: "/vendorDashboard/order-management/returned-orders",
      },
      {
        icon: <GoDash />,
        name: "Cancelled Orders",
        url: "/vendorDashboard/order-management/cancelled-orders",
      },
      {
        icon: <GoDash />,
        name: "Order's Feedback",
        url: "/vendorDashboard/order-management/feedback-orders",
      },
    ],
  },
  // {
  //   icon: <IoIosSettings />,
  //   name: "Invoice Setting",
  //   url: "/vendorDashboard/invoice-setting",
  // },
  {
    key: 5,
    icon: <IoMdInformationCircleOutline />,
    name: "Shipping Information",
    url: "/vendorDashboard/shipping-information",
  },
  {
    key: 6,
    icon: <MdManageAccounts />,
    name: "My account",
    url: "/vendorDashboard/account-management",
    childItems: [
      {
        icon: <MdManageAccounts />,
        name: "Account ",
        url: "/vendorDashboard/account-management",
      },
      {
        icon: <RiLockPasswordLine />,
        name: "Change Password  ",
        url: "/vendorDashboard/change-password",
      },
    ],
  },
  {
    key: 7,
    icon: <BsWallet2 />,
    name: "Wallet",
    url: "/vendorDashboard/wallet",
    childItems: [
      {
        icon: <BsWallet2 />,
        name: "Wallet",
        url: "/vendorDashboard/wallet",
      },
      {
        icon: <GrTransaction />,
        name: "Transaction history",
        url: "/vendorDashboard/wallet/transaction",
      },
    ],
  },
];

const UsereMnuItems = [
  {
    key: 1,
    icon: <IoIosStats />,
    name: "Dashboard",
    url: "/Dashboard",
  },

  {
    key: 2,
    icon: <MdLocalShipping />,
    name: "Orders",
    url: "/dashboard/orders",
  },
  {
    key: 3,
    icon: <AiFillHome />,
    name: "Saved Address",
    url: "/dashboard/saved-address",
  },
  {
    key: 4,
    icon: <MdFavorite />,
    name: "Wishlist",
    url: "/dashboard/wishlist",
  },

  {
    key: 5,
    icon: <MdManageAccounts />,
    name: "My account",
    url: "/Dashboard/profile",
    childItems: [
      {
        key: 1,
        pkey: 5,
        icon: <IoMdPerson />,
        name: "Profile",
        url: "/Dashboard/profile",
      },
      {
        key: 2,
        pkey: 5,
        icon: <RiLockPasswordLine />,
        name: "Change Password  ",
        url: "/Dashboard/change-password",
      },
    ],
  },
];

function Dashboard(props) {
  const { visibleMessage, isLoading, status, message } = useSelector(
    (state) => state.errorReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const { cartList, vendorNotifications } = useSelector(
    (state) => state.appReducer
  );
  console.log(props);
  const {
    location: { pathname },
  } = props;

  const showMenu = () => {
    let tarEle = document.getElementsByClassName("vd-main-screen-wrap");
    tarEle[0].classList.toggle("dash-menu-open");
  };

  return (
    <>
      <Loading showLoading={isLoading} />
      <CmAlert message={message} status={status} showAlert={visibleMessage} />
      <div className="vd-main-screen-wrap">
        <div className="vd-inner-wrap">
          {/* header section */}
          <div className="vd-header-wrap">
            <Header
              logo={logo}
              user={user}
              cartList={cartList}
              vendorNotifications={vendorNotifications}
            />
          </div>

          {/* middle section */}
          <div className="vd-middle-section">
            <div className="left-col cm-col">
              <Menu
                menuItems={user?.role_id === 3 ? menuItems : UsereMnuItems}
                activeMenuName={pathname}
              />
              <div
                className="mobile-menu-toggle"
                onClick={() => {
                  showMenu();
                }}
              >
                <ImCross />
                Close
              </div>
            </div>
            <div className="right-col cm-col">
              <div className="right-col-inner cm-col-inner">
                <div
                  className="mobile-menu-toggle"
                  onClick={() => {
                    showMenu();
                  }}
                >
                  <IoMdList />
                  Menu
                </div>
                {
                  user?.role_id === 3 ? (
                    <DashboardRouter /> // render Vendor dashboard Routes
                  ) : (
                    <UserDashboardRoutes />
                  ) // render user dashboard Routes
                }
              </div>
            </div>
          </div>

          {/* footer section */}
          <div className="vd-footer-wrap">
            <Footer content={`@2021 || Afrik || All Rights Reserved.`} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
