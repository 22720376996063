import React, { useEffect, useState } from "react";
import { BsWallet2 } from "react-icons/bs";
import "./Wallet.scss";
import { Link } from "react-router-dom";
import { AiOutlineTransaction } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { globalAction, SEND_MONEY_WITHDRAW_REQUEST, SHOW_LOADING } from "../../../../redux";

function Wallet() {
  const { user } = useSelector(state => state.authReducer)
  const { status } = useSelector(state => state.errorReducer)
  const [withdrawModalFlag, setwithdrawModalFlag] = useState(false)
  const [withdrawInput, setwithdrawInput] = useState(0);
  const dispatch = useDispatch()
  const [paymentMode, setPaymentMode] = useState('BANK');

  useEffect(() => {
    window.scroll({ top: 0 })
    if (status === 'success') {
      setwithdrawModalFlag(false)
    }
  }, [status])


  const handlePaymentMode = (e) => {
    e.preventDefault();
    setPaymentMode(e.target.value)
  }

  const requestForWithdrawModal = () => {
    return (
      <div className="cancel-order ">
        <Modal
          show={withdrawModalFlag}
          onHide={() => { setwithdrawModalFlag(!withdrawModalFlag) }}
          backdrop="static"
          keyboard={true}
          className="cancel-order-inner request-withdraw"
        >
          <Modal.Header closeButton>
            <Modal.Title>Money Withdraw Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Enter amount which you want withdraw from your wallet?
            <p>

              <small style={{ color: 'green', fontWeight: '500' }}><em>Wallet Balance : {Number(user?.wallet_amount).toFixed(2)} $</em></small>
            </p>
            <div className="cancel-reason-wrap">
              <label
                htmlFor="cancel-reason"
              >Amount<span></span></label>
              <input
                type="number"
                rows="5"
                className="input"
                placeholder="0.00"
                onChange={(e) => { setwithdrawInput(e.target.value) }} />

              <div className="payment-mode mt-3">
                <label>Payment Mode</label>
                <div className="btn-group payment-mode" onChange={(e)=>handlePaymentMode(e)} role="group" aria-label="Basic radio toggle button group">
                  <input type="radio" className="btn-check"  name="btnradio"  value="BANK" id="btnradio1" autocomplete="off" />
                  <label className={paymentMode==='BANK' ? "btn btn-warning mr-2" : "btn btn-outline-warning mr-2"} for="btnradio1" id="checkedBank">BANK</label>

                  <input type="radio" className="btn-check" name="btnradio" id="btnradio3" value="PAYPAL" autocomplete="off" />
                  <label className={paymentMode==='PAYPAL' ? "btn btn-warning" : "btn btn-outline-warning"} for="btnradio3" id="checkedPaypal">PAYPAL</label>
                </div>
               
               {
                (paymentMode === 'BANK' ?
                <div className="bank-payment-info mt-2" >
                  <div className="row mb-2 text-muted">
                    <div className="col-sm-6 py-1">Bank Name: </div>
                    <div className="col-sm-6 py-1">Bank Account No: </div>
                    <div className="col-sm-6 py-1">IFSC Code: </div>
                    <div className="col-sm-6 py-1">Account Holder: </div>
                  </div>
                  <small className="text-muted"><i>The amount will be reflected on your bank account with in 72 hours after the withdrawal.</i></small>
                </div>
                :
                <div className="paypal-payment-info mt-3">
                  <label>Enter your Paypal Id:</label>
                  <input
                    type="email"
                    rows="5"
                    className="input"
                    placeholder="paypal@email.com"
                  />
                </div>
                
                )
               }
                

              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary"
              onClick={() => { setwithdrawModalFlag(!withdrawModalFlag) }}
            >
              Cancel
            </Button>
            <Button variant="danger"
              onClick={(e) => {
                handleWithdrawRequest(e)
              }}
            >
              Send Request </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  const handleWithdrawRequest = (e) => {
    e.preventDefault()
    if (withdrawInput.trim() === '' || (Number(user?.wallet_amount) < Number(withdrawInput))) {
      alert('Please enter valid amount')
    }
    else {
      let temp = {
        vendor_id: user?.id,
        amount: Number(withdrawInput).toFixed(2)
      }
      console.log(temp)
      dispatch(globalAction(SHOW_LOADING))
      dispatch(globalAction(SEND_MONEY_WITHDRAW_REQUEST, temp))
    }
  }


  return (
    <>
      <div className="vd-dashboard-wrap order-management">
        <div className="title">
          <h3>Wallet</h3>
        </div>
        <div className="wallet box-size">
          <div className="wallet_inner">
            <div className="_card">
              <div className="card_img">
                <BsWallet2 />
              </div>
              <div className="card_content">
                <h5>
                  Available Balance <span> ${Number(user?.wallet_amount).toFixed(2)}</span>
                </h5>
              </div>
            </div>

            <div className="_card">
              <div className="card_img">
                <BsWallet2 />
              </div>
              <div className="card_content">
                <h5>
                  Last Withdraw Balance <span className="text-danger"> ${Number(user?.wallet_amount).toFixed(2)}</span>
                </h5>
              </div>
            </div>
            <div className="_card"
              onClick={() => { setwithdrawModalFlag(!withdrawModalFlag) }}>
              <div className="card_img">
                <AiOutlineTransaction />
              </div>
              <div className="card_content">
                <h5>Withdraw Balance</h5>
                <p>Request for Withdraw</p>
              </div>
              <Link to="#"></Link>
            </div>
          </div>

          <div className="vds-row-3 payout-wrap mt-3">
            <div className="vds-row-inner">
              <div className="vds-row-heading">
                <h4 className="text-center">Received Payout in 2021</h4>
              </div>
              <div className="vds-row-chats">
                <Chart
                  width={'100%'}
                  height={'400px'}
                  chartType="LineChart"
                  loader={<div>Loading Chart</div>}
                  data={[
                    ['x', 'dogs'],
                    [0, 0],
                    [1, 10],
                    [2, 23],
                    [3, 17],
                    [4, 18],
                    [5, 9],
                    [6, 11],
                    [7, 27],
                    [8, 33],
                    [9, 40],
                    [10, 32],
                    [11, 35],
                  ]}
                  options={{
                    hAxis: { title: 'Months', minValue: 0, maxValue: 15 },
                    vAxis: { title: 'Payout', minValue: 0, maxValue: 15 },
                    legend: 'none',
                    animation: {
                      startup: true,
                      easing: 'linear',
                      duration: 1500,
                    },
                    enableInteractivity: false,
                  }}
                  chartEvents={[
                    {
                      eventName: 'animationfinish',
                      callback: () => {
                        // console.log('Animation Finished')
                      },
                    },
                  ]}
                  rootProps={{ 'data-testid': '2' }}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      {requestForWithdrawModal()}

    </>
  );
}

export default Wallet;
