import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './ProductDetailPage.scss'
import ReactStars from "react-rating-stars-component";
import { AiFillStar } from 'react-icons/ai';
import { GrFacebookOption, GrNext, GrPrevious, GrTwitter } from 'react-icons/gr';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import gImage1 from '../../assets/img/product-img005.png'
import gImage2 from '../../assets/img/product-img036.png'
import productDemo from '../../assets/img/productDemo.jpg'
import AdvImage1 from '../../assets/img/reebok.png'
import Slider from 'react-slick';
import { Tab, Tabs } from 'react-bootstrap';
import RelatedProduct from '../../components/RelatedProduct/RelatedProduct';
import ReactImageMagnify from 'react-image-magnify';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_PRODUCT_TO_WISHLIST,
     ADD_REVIEW_BY_USER, 
     ADD_TO_CART, 
     DELETE_CART_ITEM, 
     GET_PRODUCT_DETAILS_BY_ID, 
     globalAction, 
     SET_CART_ITEMS, 
     SHOW_LOADING,
      UPDATE_CART_ITEM_QTY } from '../../redux';
import parse from 'html-react-parser';
import Loading from '../../components/Loading/Loading';
import CmAlert from '../../components/CmAlert/CmAlert';
import moment from 'moment';




const galleryImage = [
    {
        imageUrl: gImage1
    },
    {
        imageUrl: gImage2
    },
    {
        imageUrl: gImage1
    },
    {
        imageUrl: gImage2
    },
    {
        imageUrl: gImage1
    },
    {
        imageUrl: gImage2
    },
    {
        imageUrl: gImage1
    }
]

const AvdImage = [
    {
        imageUrl: AdvImage1
    },
    {
        imageUrl: AdvImage1
    },
    {
        imageUrl: AdvImage1
    },
    {
        imageUrl: AdvImage1
    },
    {
        imageUrl: AdvImage1
    },
    {
        imageUrl: AdvImage1
    },
    {
        imageUrl: AdvImage1
    }
]

const customShareIcon = (iconName) => {
    if (iconName === 'facebook') {
        return (
            <div className="iconWrap">
                <GrFacebookOption />
                <span>Share</span>
            </div>
        )
    }
    else if (iconName === 'twitter') {
        return (
            <div className="iconWrap">
                <GrTwitter />
                <span>Share</span>
            </div>
        )
    }
    else if (iconName === 'telegram') {
        return (
            <div className="iconWrap">
                <FaTelegramPlane />
                <span>Share</span>
            </div>
        )
    }
    else if (iconName === 'whatsapp') {
        return (
            <div className="iconWrap">
                <FaWhatsapp />
                <span>Share</span>
            </div>
        )
    }
    else {

    }

}






function ProductDetailPage(props) {
    const { productDetails,
        cartList,
        cartTotal,
        relatedProducts,
        productVariant,
        selectedVariant } = useSelector(state => state.appReducer);
    const { user } = useSelector(state => state.authReducer);
    const { isLoading } = useSelector(state => state.errorReducer);
    const dispatch = useDispatch();
    const getQty = useRef()
    const [galleryActiveImg, setgalleryActiveImg] = useState(undefined);
    const { match: { params } } = props;
    const [qty, setQty] = useState(1)
    const [rating, setRating] = useState(undefined)
    const [review, setReview] = useState(undefined)
    const [finalReviews, SetfinalReviews] = useState({});
    const [LocalProductDetails, setLocalProductDetails] = useState(undefined)
    const [productHasVariant, setProductHasVariant] = useState(false)
    const { history } = props;

    let vParams = { slug: params.Id }

    useEffect(() => {
        dispatch(globalAction(SHOW_LOADING))
        dispatch(globalAction(GET_PRODUCT_DETAILS_BY_ID, { slug: params.Id }))
    }, [params.Id])

    const galleryActiveImgHandle = (imageUrl) => {
        setgalleryActiveImg(imageUrl)
    }

    const addToCart = (item) => {
        localStorage.removeItem('buyNow')
        dispatch(globalAction(SHOW_LOADING))
        item['qty'] = qty
        dispatch(globalAction(ADD_TO_CART, { item: item, user: user }))
    }

    const buyNow = (item) => {
        dispatch(globalAction(SHOW_LOADING))
        item['qty'] = qty
        let tempObj = [item]
        localStorage.setItem('buyNow', JSON.stringify(tempObj))
        history.push('/shopping-cart')
    }

    const addToWishlist = (productId) => {
        if (user) {
            dispatch(globalAction(SHOW_LOADING))
            dispatch(globalAction(ADD_PRODUCT_TO_WISHLIST, { product_id: productId, user_id: user?.id }))
        }
        else {
            alert('Please Login with customer account')
        }
    }

    const changeQty = (e, typeOfChange, item) => {
        let qtyChangeItemIndex = undefined;
        let tempQty = Number(getQty.current.innerText);
        cartList.forEach((cartItem, i) => {
            if (cartItem.id === item.id) {
                qtyChangeItemIndex = i
                return qtyChangeItemIndex;
            }
        })
        if (typeOfChange === 'minus' && tempQty > 0) {
            tempQty = tempQty - 1
            setQty(tempQty)
        }
        else {
            tempQty = tempQty + 1
            if(tempQty <= item.stock_qty){
                setQty(tempQty)
            }
        }
        if (qtyChangeItemIndex !== undefined) { // this condition to add item into cart if user change qty from this page
            cartList[qtyChangeItemIndex]['qty'] = qty;
            dispatch(globalAction(UPDATE_CART_ITEM_QTY, { cartList: cartList, user: user }))
        }
    }

    const settings = {
        dots: false,
        infinite: false,
        verticalSwiping: true,
        vertical: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <GrNext />,
        prevArrow: <GrPrevious />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    verticalSwiping: false,
                    vertical: false,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    dots: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    verticalSwiping: false,
                    vertical: false,
                },
            },
            {
                breakpoint: 460,
                settings: {
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    verticalSwiping: false,
                    vertical: false,
                },
            }

        ],
    };

    const getProductByVariant = function (value, paramName) {
        dispatch(globalAction(SHOW_LOADING))
        vParams[paramName] = value;
        dispatch(globalAction(GET_PRODUCT_DETAILS_BY_ID, vParams))
    }


    useEffect(() => {
        window.scroll({ top: 0 })
        if (LocalProductDetails === undefined) {
            setLocalProductDetails(productDetails)
        }
        if (LocalProductDetails) {
            setQty(LocalProductDetails?.minimum_qty_per_order || 1)
            SetfinalReviews(LocalProductDetails?.product_reviews)
        }
        if (LocalProductDetails && cartList) {
            cartList.forEach((cartItem, i) => {
                if (cartItem.id === LocalProductDetails.id) {
                    setQty(cartItem.qty)
                }
            })
        }
        if (productVariant?.product_variants !== undefined) {
            setProductHasVariant(productVariant?.product_variants !== undefined)
            setQty(productVariant?.product_variants?.minimum_qty_per_order || 1)
            setLocalProductDetails({
                ...LocalProductDetails,
                regular_price: productVariant?.product_variants?.regular_price,
                sale_price: productVariant?.product_variants?.sale_price,
                stock_qty: productVariant?.product_variants?.stock_qty,
                variant_name: productVariant?.product_variants?.variant_name,
                variant_name_2: productVariant?.product_variants?.variant_name_2,
                variant_name_3: productVariant?.product_variants?.variant_name_3,
                image_url: productVariant?.product_variants?.image,
                product_images: productVariant?.product_variants?.product_variant_images,
                description: productVariant?.product_variants?.short_desc

            })

        }
    }, [productDetails, cartList, cartTotal, productVariant])




    const HandleRatingChange = (newRating) => {
        setRating(newRating)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let finalObj = {
            user_id: user?.id,
            product_id: LocalProductDetails?.id,
            rating: rating,
            review: review,
            slug: LocalProductDetails?.slug
        }

        dispatch(globalAction(SHOW_LOADING))
        dispatch(globalAction(ADD_REVIEW_BY_USER, finalObj))

    }

    const reviewSorting = (productReviews, typeOfSorting) => {
        let tempArray = []
        if (productReviews !== undefined && productReviews.length > 0) {
            tempArray = productReviews.filter(
                (review) => Math.round(review.rating) === Number(typeOfSorting)
            );
        }
        SetfinalReviews(tempArray)
        if (Number(typeOfSorting) === 0) {
            SetfinalReviews(productReviews)
        }
    };

    const onDropDownSelect = (e, allReviews) => {
        let rating = e.target.value
        reviewSorting(allReviews, rating)
    };





    return (
        <>

            <div className='product-detail-wrap'>
                <Loading
                    showLoading={isLoading}
                />
                <div className='product-detail-inner '>
                    <div className="breadCum-wrap cm-container">
                        <ul>
                            <li>
                                <Link 
                                    to='/home'
                                >Home
                                </Link>
                            </li>
                            {LocalProductDetails?.category?.slug &&
                                <li>
                                    <span>/</span>
                                    <Link 
                                        to={`/products/${LocalProductDetails?.category?.slug}`}
                                    >{LocalProductDetails?.category_name}
                                    </Link>
                                </li>
                            }

                            {LocalProductDetails?.subcategory?.slug &&
                                <li>
                                    <span>/</span>
                                    <Link 
                                        to={`/products/${LocalProductDetails?.category?.slug}/${LocalProductDetails?.subcategory?.slug}`}
                                    >{LocalProductDetails?.sub_category_name}</Link>
                                </li>
                            }
                            {LocalProductDetails?.title &&
                                <li>
                                    <span>/</span>

                                    {LocalProductDetails?.title}
                                </li>
                            }

                        </ul>

                    </div>
                    <div className="product-section ">
                        <div className="product-section-inner cm-container">
                            <div className="p-right clearfix">
                                <div className="pc-gallery-wrap">
                                    <Slider {...settings}
                                    >
                                        {LocalProductDetails?.product_images?.map((item, i) => {
                                            return (
                                                <div className="pc-gallery-item"
                                                    onClick={() => { galleryActiveImgHandle(item.image) }}
                                                >
                                                    <img src={item.image} alt={LocalProductDetails?.title} srcset="" />
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                                <div className="pc-main-image-wrap">
                                    <ReactImageMagnify style={{ zIndex: 8 }}
                                        smallImage={{
                                            alt: "Wristwatch by Versace",
                                            isFluidWidth: true,
                                            src: galleryActiveImg || LocalProductDetails?.image_url || productDemo,
                                        }}
                                        largeImage={{
                                            src: galleryActiveImg || LocalProductDetails?.image_url || productDemo,
                                            width: 846,
                                            height: 900
                                        }}
                                        enlargedImageContainerDimensions={{
                                            width: "100%",
                                            height: "100%"
                                        }}
                                        lensStyle={{
                                            background: "hsla(0, 0%, 100%, .4)",
                                        }}
                                        enlargedImageStyle={{
                                            maxWidth: 'unset',
                                            maxHeight: 'unset',
                                            minWidth: 'unset'
                                        }}
                                        shouldUsePositiveSpaceLens={false}
                                        enlargedImageContainerClassName='gallery-larger-image'

                                    />
                                    {/* <img src={gImage1} alt="" /> */}
                                    <div className="discount-wrap">
                                        <span>10%</span>
                                    </div>
                                </div>

                            </div>
                            <div className="p-left product-content">
                                <div className="pc-title">
                                    <h1>{LocalProductDetails?.title}
                                        <small> {LocalProductDetails?.variant_name} {LocalProductDetails?.variant_name_2}
                                            {LocalProductDetails?.variant_name_3}</small>
                                    </h1>
                                    <p>Sold by :
                                        {LocalProductDetails?.user?.company_name ?
                                            <Link to={`/vendor-store/${productDetails?.user?.id}`}>
                                                {LocalProductDetails?.user?.company_name}</Link>
                                            :
                                            <Link to={`/`}>
                                                Du-Afrik</Link>

                                        }
                                    </p>
                                </div>
                                <div className="pc-desc">
                                    {/* {parse(productDetails?.description.slice(0 ,200) || '')} */}
                                </div>
                                <div className="pc-review">
                                    {LocalProductDetails?.avg_rating &&
                                        <span className='star-icon'>
                                            <ReactStars
                                                count={5}
                                                // onChange={ratingChanged}
                                                size={24}
                                                value={productDetails?.avg_rating}
                                                edit={false}
                                                isHalf={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                activeColor="#ffd700"
                                            />
                                        </span>
                                    }
                                    {!LocalProductDetails?.avg_rating &&
                                        <span className='star-icon'>
                                            <ReactStars
                                                count={5}
                                                // onChange={ratingChanged}
                                                size={24}
                                                value={0}
                                                edit={false}
                                                isHalf={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                activeColor="#ffd700"
                                            />
                                        </span>
                                    }
                                    <span className='review-count'>({LocalProductDetails?.product_review_count} Reviews)</span>
                                    {/* <span className='total-sold'> | 9 Sold</span> */}

                                </div>
                                <div className="pc-price">
                                    {LocalProductDetails?.sale_price !== null ?
                                        <p>
                                            <span className="saleprice">
                                                {LocalProductDetails?.sale_price}$
                                            </span>
                                            <del className="regular-price">
                                                {
                                                    (LocalProductDetails && LocalProductDetails.regular_price != LocalProductDetails.sale_price) ?
                                                        LocalProductDetails.regular_price + '$'
                                                    :
                                                        ''
                                                }
                                            </del>

                                        </p>
                                        :
                                        <p>
                                            <span className="saleprice">
                                                {LocalProductDetails?.regular_price} $
                                            </span>

                                        </p>
                                    }


                                    {/* <p><strong>Brand : </strong> {productDetails?.brand_name}</p> */}
                                </div>

                                {
                                    productHasVariant &&
                                    <>
                                        {(productVariant?.variant_1?.length > 0 && productVariant.product_variants?.attribute1 !== null) &&
                                            <div className="variant 1">
                                                <select name="" id=""
                                                    onChange={(e) => getProductByVariant(e.target.value, 'variant_1')}
                                                >
                                                    {productVariant?.variant_1.map((item, i) => {
                                                        return (
                                                            <option selected={productVariant.product_variants?.variant_name === item} value={item}>{item}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        }

                                        {(productVariant?.variant_2?.length > 0 && productVariant.product_variants?.attribute2 !== null) &&
                                            <div className="variant 2">
                                                <select name="" id=""
                                                    onChange={(e) => getProductByVariant(e.target.value, 'variant_2')}
                                                >
                                                    {productVariant?.variant_2.map((item, i) => {
                                                        return (
                                                            <option
                                                                selected={productVariant.product_variants?.variant_name_2 === item}
                                                                value={item}>{item}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        }

                                        {(productVariant?.variant_3?.length > 0 && productVariant.product_variants?.attribute3 !== null) &&
                                            <div className="variant 3">
                                                <select name="" id=""
                                                    onChange={(e) => getProductByVariant(e.target.value, 'variant_3')}
                                                >
                                                    {productVariant?.variant_3.map((item, i) => {
                                                        return (
                                                            <option
                                                                selected={productVariant.product_variants?.variant_name_3 === item}
                                                                value={item}>{item}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        }


                                    </>
                                }

                                <div className="pc-stock-status">
                                    <p>
                                        {(LocalProductDetails?.stock_qty > LocalProductDetails?.minimum_qty_per_order) ?
                                            <span>
                                                {LocalProductDetails?.stock_qty} in stock
                                            </span>
                                            :
                                            <span class="out-of-stock">
                                                Out of Stock
                                            </span>
                                        }

                                    </p>
                                </div>

                                <div className="pc-actions clearfix">
                                    {(productDetails?.stock_qty > productDetails?.minimum_qty_per_order) &&
                                        < div className="qty-actions">
                                            {(qty > 1 && qty > productDetails?.minimum_qty_per_order) &&
                                                <span
                                                    className='qty-btn'
                                                    onClick={(e) => { changeQty(e, 'minus', productDetails) }}
                                                >-</span>
                                            }

                                            <span ref={getQty}>
                                                {/* {productDetails?.minimum_qty_per_order !== null ? productDetails?.minimum_qty_per_order : 1} */}
                                                {qty}
                                            </span>

                                            <span
                                                className='qty-btn'
                                                onClick={(e) => { changeQty(e, 'plus', productDetails) }}
                                            >+</span>
                                        </div>
                                    }
                                    {true &&
                                        <div className="add-to-wishlist">
                                            <div
                                                onClick={() => { addToWishlist(productDetails?.id) }}
                                            ><MdOutlineFavoriteBorder /></div>
                                        </div>
                                    }

                                </div>
                                {(LocalProductDetails?.stock_qty > LocalProductDetails?.minimum_qty_per_order) ?
                                    <div className="pc-actions btn-wrap clearfix">
                                        <div className="add-to-cart">
                                            <button
                                                className='btn-black'
                                                onClick={() => addToCart(productDetails)}
                                            >Add To Cart</button>
                                        </div>

                                        <div className="add-to-cart">
                                            <button
                                                className='btn-brown buyNow'
                                                onClick={() => buyNow(productDetails)}
                                            >Buy Now</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="pc-actions btn-wrap clearfix">
                                        <div className="add-to-cart">
                                            <button
                                                className='btn-black outofstock'
                                            >Out Of Stock</button>
                                        </div>


                                    </div>
                                }


                                <div className="product-meta">
                                    <p><span>Brand : </span>{LocalProductDetails?.brand_name}</p>
                                    <p><span>Model Number : </span> {LocalProductDetails?.model_no}</p>
                                    <p><span>Category : </span> {LocalProductDetails?.category_name} / {LocalProductDetails?.sub_category_name}</p>
                                    <p><span>Tags : </span><strong>{LocalProductDetails?.tags?.split(', ').reduce((acc, item) => {
                                        return acc = acc + ' #' + item;
                                    }, '')}
                                    </strong></p>
                                </div>
                                {/* <div className="pc-desc">
                                    {parse(productDetails?.description.slice(0, 250) || '')}
                                </div> */}

                                <div className="social-share">
                                    <div className="social-share-inner clearfix">
                                        <div className="social-item facebook">
                                            <FacebookShareButton
                                                url={window.location.href}
                                                children={customShareIcon('facebook')}
                                            />
                                        </div>
                                        <div className="social-item twitter">
                                            <TwitterShareButton
                                                url={window.location.href}
                                                children={customShareIcon('twitter')}
                                            />

                                        </div>
                                        <div className="social-item whatsapp">
                                            <WhatsappShareButton
                                                url={window.location.href}
                                                children={customShareIcon('whatsapp')}
                                            />
                                        </div>
                                        <div className="social-item telegram">
                                            <TelegramShareButton
                                                url={window.location.href}
                                                children={customShareIcon('telegram')}
                                            />
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="frequently-brought-wrap">
                        <div className="fbw-inner clearfix cm-container">
                            <div className="fbw-content">
                                <h5>Frequently Bought Together</h5>
                                <div className="fbw-items clearfix">

                                    <div className="fbw-item">
                                        <input type="checkbox"
                                            id='temp'
                                        />
                                        <label className='checkbox-label' htmlFor="temp">
                                            <img src={gImage1} alt="" />
                                            <div className="pc-price">
                                                <p>
                                                    <span className="saleprice">
                                                        200.15 $
                                                    </span>
                                                    <del className="regular-price">
                                                        300.15 $
                                                    </del>

                                                </p>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="fbw-item">
                                        <input type="checkbox"
                                            id='temp1'
                                        />
                                        <label className='checkbox-label' htmlFor="temp1">
                                            <img src={gImage1} alt="" />
                                            <div className="pc-price">
                                                <p>
                                                    <span className="saleprice">
                                                        200.15 $
                                                    </span>
                                                    <del className="regular-price">
                                                        300.15 $
                                                    </del>

                                                </p>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="fbw-item">
                                        <input type="checkbox"
                                            id='temp2'
                                        />
                                        <label className='checkbox-label' htmlFor="temp2">
                                            <img src={gImage1} alt="" />
                                            <div className="pc-price">
                                                <p>
                                                    <span className="saleprice">
                                                        200.15 $
                                                    </span>
                                                    <del className="regular-price">
                                                        300.15 $
                                                    </del>

                                                </p>
                                            </div>
                                        </label>
                                    </div>

                                    <div className="fbw-item fbw-btn">
                                        <h6>Price for all</h6>
                                        <p>500.00 $</p>
                                        <button class="btn-black">Add All To Cart</button>
                                    </div>

                                </div>

                            </div>
                            <div className="fbw-adv">
                                <Slider
                                    dots={false}
                                    infinite={true}
                                    speed={500}
                                    slidesToShow={1}
                                    slidesToScroll={1}
                                    nextArrow={<GrNext />}
                                    prevArrow={<GrPrevious />}
                                >
                                    {AvdImage.map((item, i) => {
                                        return (
                                            <div className="fbw-item">
                                                <Link to=''>
                                                    <img src={item.imageUrl} alt="avg" />
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div> */}

                    <div className="product-tabber">
                        <div className="product-tabber-inner cm-container">
                            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="home" title="Description">
                                    {parse(LocalProductDetails?.description || '')}
                                </Tab>

                                <Tab eventKey="profile" title={`Reviews(${LocalProductDetails?.product_review_count})`}>

                                    <div className="review-tab-wrap">
                                        <div className="review-inner">
                                            <div className="title">
                                                <h2>Customer Reviews</h2>
                                                <p>Customer reviews on dynamic product name</p>
                                            </div>
                                            <div className="review-summary-wrap">
                                                <div className="review-wrap ">
                                                    <div className="star">
                                                        <div>
                                                            <AiFillStar />
                                                            <span>{Number(LocalProductDetails?.avg_rating).toFixed(1)}</span>
                                                        </div>
                                                        <p>Average rating based on {LocalProductDetails?.product_review_count} ratings</p>
                                                    </div>
                                                    <div className="reting">
                                                        <div className="reting_inner">
                                                            <ReactStars
                                                                count={5}
                                                                // onChange={this.ratingChanged}
                                                                size={20}
                                                                isHalf={true}
                                                                edit={false}
                                                                activeColor="#ffd731"
                                                            />
                                                            <span>
                                                                {5}
                                                            </span>
                                                        </div>
                                                        <div className="reting_inner">
                                                            <ReactStars
                                                                count={4}
                                                                // onChange={ratingChanged}
                                                                size={20}
                                                                isHalf={true}
                                                                edit={false}
                                                                activeColor="#ffd731"
                                                            />
                                                            <span>
                                                                {4}
                                                            </span>
                                                        </div>
                                                        <div className="reting_inner">
                                                            <ReactStars
                                                                count={3}
                                                                //   onChange={this.ratingChanged}
                                                                size={20}
                                                                isHalf={true}
                                                                edit={false}
                                                                activeColor="#ffd731"
                                                            />
                                                            <span>
                                                                {3}
                                                            </span>
                                                        </div>
                                                        <div className="reting_inner">
                                                            <ReactStars
                                                                count={2}
                                                                //   onChange={this.ratingChanged}
                                                                size={20}
                                                                isHalf={true}
                                                                edit={false}
                                                                activeColor="#ffd731"
                                                            />
                                                            <span>
                                                                {2}
                                                            </span>
                                                        </div>
                                                        <div className="reting_inner">
                                                            <ReactStars
                                                                count={1}
                                                                //   onChange={this.ratingChanged}
                                                                size={20}
                                                                isHalf={true}
                                                                edit={false}
                                                                activeColor="#ffd731"
                                                            />
                                                            <span>
                                                                {1}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {user?.role_id == 2 &&
                                                    <div className="review-form">

                                                        <p>Give review which helps our other customers</p>
                                                        <form
                                                            action=""
                                                            onSubmit={(e) => { handleSubmit(e) }}
                                                        >
                                                            <div className="rl-user-info">
                                                                <ReactStars
                                                                    count={5}
                                                                    onChange={HandleRatingChange}
                                                                    size={20}
                                                                    isHalf={true}
                                                                    edit={true}
                                                                    activeColor="#ffd731"
                                                                />

                                                            </div>
                                                            <input
                                                                type="text"
                                                                required
                                                                className='input'
                                                                onChange={(e) => setReview(e.target.value)}
                                                            />
                                                            <p>Rating the product</p>
                                                            <button
                                                                className='btn-black'
                                                                type='Submit'
                                                            > Add Review</button>
                                                        </form>


                                                    </div>
                                                }

                                            </div>
                                            <div className="rating-list-wrap">
                                                <div className="top-section">
                                                    <h5>
                                                        Customer Reviews
                                                    </h5>
                                                    <div class="filter">
                                                        <span>FILTER BY :</span>
                                                        <select name="cars" class="sortingRating"
                                                            onChange={(e) => { onDropDownSelect(e, productDetails?.product_reviews) }}
                                                        >
                                                            <option value="0">All</option>
                                                            <option value="1">1 Start Rating</option>
                                                            <option value="2">2 Start Rating</option>
                                                            <option value="3">3 Start Rating</option>
                                                            <option value="4">4 Start Rating</option>
                                                            <option value="5">5 Start Rating</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="rating-listing">
                                                    <div className="rating-listing-inner">
                                                        {finalReviews?.length > 0
                                                            ?
                                                            finalReviews?.map((item, i) => {

                                                                return (
                                                                    <div className="rating-list-item">
                                                                        <div className="rl-user-info">
                                                                            <ReactStars
                                                                                count={item.rating}
                                                                                value={item.rating}

                                                                                // onChange={this.ratingChanged}
                                                                                size={20}
                                                                                isHalf={true}
                                                                                edit={false}
                                                                                color2="#ffd731"
                                                                            />
                                                                            <h6>{item?.name}</h6>
                                                                            <data>{moment(item.created_at).format('LL')}</data>
                                                                            <p>Verified Customer</p>
                                                                        </div>
                                                                        <div className="rating-content">
                                                                            <h6>
                                                                                {item?.review.slice(0, 20)}...
                                                                            </h6>
                                                                            <p>{item.review}</p>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })


                                                            :
                                                            <div className="">
                                                                <p>No reviews yet.Congrats you are first..</p>
                                                            </div>
                                                        }




                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Tab>

                                {/* <Tab eventKey="contact" title="Contact">
                              'contact'
                            </Tab>
                            <Tab eventKey="contact2" title="Contact2" >
                               'contact2'
                            </Tab> */}
                            </Tabs>
                        </div>
                    </div>

                    {relatedProducts &&
                        relatedProducts.length > 0 &&
                        <RelatedProduct
                            data={relatedProducts}
                            user={user}
                        />
                    }


                </div>
            </div>
        </>
    )
}

export default ProductDetailPage
