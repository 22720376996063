import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import "./become-partner.scss";

import Partner1 from "../../assets/img/partner1.png";
import Partner2 from "../../assets/img/partner2.png";
import Partner3 from "../../assets/img/partner3.png";
import sellBanner from "../../assets/img/sellBanner.jpg";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { globalAction, SHOW_LOADING, SELL_ON } from "../../redux";
import { useDispatch } from "react-redux";
import AllCountry from '../../Utilis/allCountry.json'


function BecomePartner() {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(undefined)
  const formRef = useRef()

  const handleSubmit = ((e) => {
    e.preventDefault();
    dispatch(globalAction(SHOW_LOADING))
    dispatch(globalAction(SELL_ON,formData))
    formRef.current.reset()
  })

  const changeText = (value, text) => {
    setFormData({ ...formData, [text]: value })
  }

  useEffect(() => {
    window.scroll({ top: 0 })
  }, [])

  return (
    <section className="become_partner">
      <>
        <HomeBanner
          img={sellBanner}
        // bannerTitle="How To Sell With Us" 
        />
      </>
      <div className="container">
        <div className="spacing">
          <div className="page_title">
            <h3>How to sell on DuAfrik</h3>
          </div>

          <div className="kyc">
            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h4>

            <div className="card_wrap">
              <div className="kyc_card">
                <img src={Partner1} alt="" />
                <div className="card_body">
                  <h4>Register</h4>
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                  <Link to="/">Learn More..</Link>
                </div>
              </div>
              <div className="kyc_card">
                <img src={Partner2} alt="" />
                <div className="card_body">
                  <h4>KYC</h4>
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                  <Link to="">Learn More..</Link>
                </div>
              </div>
              <div className="kyc_card">
                <img src={Partner3} alt="" />
                <div className="card_body">
                  <h4>Start selling</h4>
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                  <Link to="">Learn More..</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacing">
          <div className="page_title">
            <h3><span>Sell on Du- Afrik</span></h3>
          </div>

          <div className="form-wrap">
            <Form
              ref={formRef}
              onSubmit={(e) => { handleSubmit(e) }}
            >
              <Form.Group className="mb-4" controlId="name">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter full name"
                  className="input"
                  onChange={(e) => changeText(e.target.value, 'name')}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  type="email"
                  placeholder="Enter name"
                  className="input"
                  onChange={(e) => changeText(e.target.value, 'email')}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="mobile">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Mobile number"
                  className="input"
                  onChange={(e) => changeText(e.target.value, 'mobile')}
                />
              </Form.Group>
              <Form.Group className="mb-4" controlId="Country">
                <Form.Label>Country </Form.Label>
                <Form.Select
                  aria-label="Floating label select example"
                  required
                  className="input"
                  // onLoad={() => {
                  //     dataForUpdate?.country && getStateByCountryId(Number((dataForUpdate?.country)))
                  // }}
                  onChange={(e) => changeText(e.target.value, 'country')}
                >
                  <option>Select country</option>
                  {AllCountry.map((indCountry, i) => {
                    return (
                      <option
                        key={indCountry.name}
                        value={indCountry.id}
                      // selected={formData?.country == indCountry.id}
                      >{indCountry.name}</option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-4" controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Company Name"
                  className="input"
                  onChange={(e) => changeText(e.target.value, 'companyName')}
                />
              </Form.Group>
            
              <Form.Group className="mb-4" controlId="companyAddress">
                <Form.Label>Company Address</Form.Label>
                <Form.Control
                  required={true}
                  type="text"
                  placeholder="Enter company address..."
                  as="textarea"
                  style={{ height: '100px' }}
                  className="input"
                  onChange={(e) => changeText(e.target.value, 'companyAddress')}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                      required={true}
                      type="text"
                      placeholder="Enter message..."
                      as="textarea"
                      style={{ height: '100px' }}
                      className="input"
                      onChange={(e) => changeText(e.target.value, 'message')}
                  />
              </Form.Group>

              <Form.Group className="mb-4" controlId="files">
                <Form.Label>Attachment</Form.Label>
                <Form.Control
                  required={true}
                  type="file"
                  className="input"
                  onChange={(e) => changeText(e.target.files[0], 'files')}
                />
              </Form.Group>

              {/* <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    Upload
                  </span>
                </div>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                  />
                  <label class="custom-file-label" for="inputGroupFile01">
                    Choose file
                  </label>
                </div>
              </div> */}
              <Button
                variant="primary"
                type="submit"
                className="btn-brown"
              >
                Submit Request
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BecomePartner;
