import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getPaymentSettingApi } from '../../api';
import { BOOK_ORDER_BY_USER, globalAction, SHOW_LOADING } from '../../redux';


function Paypal(props) {
    const { orderInfo, orderInfo:{cartList,cartTotal,ShippingInfo,user,history,guestData,clientId} } = props;
    const dispatch = useDispatch()



    const handlePlaceOrder = (orderDetails) => {
        let order_product = []
        cartList?.forEach(item => {
            const { id, qty, sale_price, regular_price } = item;
            let price = item.regular_price;
            if (item.sale_price !== null) {
                price = item.sale_price;
            }
            order_product.push({
                product_id: id,
                qty: qty,
                single_price: sale_price !== null ? sale_price : regular_price,
                total_price: Number(price) * Number(qty),
            });
        });
        let finalOrderObject = {
            order_duration: 5,
            order_quantity: cartList.length,
            order_price: cartTotal.toFixed(2),
            payment_type: orderDetails.purchase_units[0].soft_descriptor||'PAYPAL',
            order_status: 'pending',
            shipping_addess: ShippingInfo?.shipping_addess?.id,
            billing_address: ShippingInfo?.billing_address?.id,
            order_product,
            gst_billing: 'no',
            user_type: 'user',
            shiping_fee: 200,
            transaction_id:orderDetails.purchase_units[0].payments.captures[0].id
        }
        if (user!==null) {
            finalOrderObject = { ...finalOrderObject, user_id: user?.id }
            dispatch(globalAction(SHOW_LOADING));
            dispatch(globalAction(BOOK_ORDER_BY_USER, { finalOrderObject: finalOrderObject, history: history }));
        }
        
        else {
            finalOrderObject={...finalOrderObject,...guestData}
            dispatch(globalAction(SHOW_LOADING));
            dispatch(globalAction(BOOK_ORDER_BY_USER, { finalOrderObject: finalOrderObject, history: history }));
        }
    }

  


    return (
        <>
        {clientId !==undefined &&
             <PayPalScriptProvider
             options={{
                 "client-id": clientId
             }}
         >
      
         <PayPalButtons
             style={{
                 tagline: false,
                 label:  'pay'
             }}
             createOrder={(data, actions) => {
                 //  set up transaction
                 return actions.order.create({
                     purchase_units: [{
                         amount: {
                             value: orderInfo?.cartTotal,
                         }
                     }
                     ]
                 })
             }}

             onApprove={async (data, actions) => {
                 const order = await actions.order.capture()
                 handlePlaceOrder(order)

             }}

             onCancel={(data, actions) => {
                 alert('Are you sure to cancel..?')
             }}

             onError={(err) => {
                 alert('Something went wrong. we not processed your request. Please try again')
             }}
         />
            </PayPalScriptProvider>
         }
       
        </>
    )
}

export default Paypal
