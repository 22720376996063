import { BrowserRouter } from "react-router-dom";
import Router from "./routing/Router";
import "bootstrap/dist/css/bootstrap.min.css";

import "../src/sass/main.scss";
import { Provider } from "react-redux";
import { store } from "./redux";




function App() {

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </>

  );
}

export default App;
