import {
    CALCULATE_CART_TOTAL_PRICE,
    SET_ADDRESS_LIST,
    SET_ADVERTISEMENT,
    SUCCESS_VENDOR_PAYMENT_GRAPH,
    SET_BLOG_CATEGORIES,
    SET_BLOG_LISTING,
    SET_BRANDS_LIST,
    SET_CART_ITEMS,
    SET_CURRENT_COUNTRY,
    SET_MAX_PRICE_IN_PRODUCT_LIST,
    SET_OFFER_LIST,
    SET_ORDER_LIST_BY_USER_ID,
    SET_PRODUCTS_SELECTED_VARIANT,
    SET_PRODUCTS_VARIANT,
    SET_PRODUCT_DETAILS,
    SET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID,
    SET_RELATED_PRODUCTS,
    SET_ORDER,
    SET_VENDOR_NOTIFICATIONS,
    SET_WISHLIST_BY_USER_ID,
    SET_WITHDRAW_REQUEST_LIST
} from "..";
import {
    SET_CATEGORY_LIST,
    SET_PRODUCT_LIST,
    SET_HOME_DATA
} from "../actionsType";

var calulateTotalPrice = (cartList) => {
    console.log(cartList)
   
    if (cartList.length > 0) {
        let total = 0
        cartList.forEach((item, i) => {
            const { regular_price, sale_price, qty } = item;
            if (sale_price !== null) {
                total+=sale_price * qty
            }
            else {
                total +=regular_price * qty
            }
        })
        return total
    }
}

let localCartList=localStorage.getItem('cartList')?JSON.parse(localStorage.getItem('cartList')):[];
let localCartTotal=calulateTotalPrice(localStorage.getItem('cartList')?JSON.parse(localStorage.getItem('cartList')):[])
// initial state
const initialState = {
    categoryList: [],
    brandsList: [],
    offerList: [],
    productList: [],
    orderInfo:[],
    maxPriceInProductList:undefined,
    productDetails:undefined,
    productVariant:[],
    selectedVariant:[],
    productVariantList:[],
    advertisementList:[],
    blogCategories:undefined,
    blogListing:undefined,
    relatedProducts:[],
    cartList:localCartList,
    cartTotal:localCartTotal,
    savedAddressList:undefined,
    orderList:undefined,
    wishList:undefined,
    withdrawList:undefined,
    homeData:undefined,
    currentCountry:undefined,
    notificationList:undefined,
    vendorPayments:[]
}

const appReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_CATEGORY_LIST: {
            return {
                ...state,
                categoryList: payload
            }
        }
        case SUCCESS_VENDOR_PAYMENT_GRAPH: {
            return {
                ...state,
                vendorPayments: payload
            }
        }
        case SET_VENDOR_NOTIFICATIONS: {
            return {
                ...state,
                notificationList: payload
            }
        }
        case SET_BRANDS_LIST: {
            return {
                ...state,
                brandsList: payload
            }
        }
        case SET_ORDER: {
            return {
                ...state,
                orderInfo: payload
            }
        }
        case SET_OFFER_LIST: {
            return {
                ...state,
                offerList: payload
            }
        }
        case SET_PRODUCT_LIST: {
            return {
                ...state,
                productList: payload
            }
        }
        case SET_MAX_PRICE_IN_PRODUCT_LIST: {
            return {
                ...state,
                maxPriceInProductList: payload
            }
        }
        case SET_PRODUCT_DETAILS: {
            return {
                ...state,
                productDetails: payload
            }
        }
        case SET_RELATED_PRODUCTS: {
            return {
                ...state,
                relatedProducts: payload
            }
        }
        case SET_PRODUCT_VARIANT_LIST_BY_PRODUCT_ID: {
            return {
                ...state,
                productVariantList: payload
            }
        }
        case SET_ADVERTISEMENT: {
            return {
                ...state,
                advertisementList: payload
            }
        }
        case SET_BLOG_LISTING: {
            return {
                ...state,
                blogListing: payload
            }
        }
        case SET_BLOG_CATEGORIES: {
            return {
                ...state,
                blogCategories: payload
            }
        }
        case SET_CART_ITEMS:{
            return {
                ...state,
                cartList: payload,
            }
        }
        case CALCULATE_CART_TOTAL_PRICE:{
            return {
                ...state,
                cartTotal: calulateTotalPrice(payload)
            }
        }
        case SET_ADDRESS_LIST:{
            return {
                ...state,
                savedAddressList: payload
            }
        }
        case SET_ORDER_LIST_BY_USER_ID:{
            return {
                ...state,
                orderList: payload
            }
        }
        case SET_WISHLIST_BY_USER_ID:{
            return {
                ...state,
                wishList: payload
            }
        }
        case SET_WITHDRAW_REQUEST_LIST:{
            return{
                ...state,
                withdrawList:payload
            }
        }

        case SET_HOME_DATA:{
            return{
                ...state,
                homeData:payload
            }
        }

        case SET_CURRENT_COUNTRY:{
            return{
                ...state,
                currentCountry:payload
            }
        }

        case SET_PRODUCTS_VARIANT:{
            return{
                ...state,
                productVariant:payload
            }
        }

        case SET_PRODUCTS_SELECTED_VARIANT:{
            return{
                ...state,
                selectedVariant:payload
            }
        }
        
        default: {
            return state;
        }
    }


}

export default appReducer