/** @format */

import React, { useEffect } from "react";
import SeoSetting from "../../components/SeoSetting/SeoSetting";
import "./Home.scss";
import Slider1 from "../../components/Slider-1/Slider1";
import Slider3 from "../../components/Slider-3/Slider3";
import Slider4 from "../../components/Slider-4/Slider4";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_HOME_DATA,
  GET_PRODUCT_LIST_FOR_USER,
  globalAction,
  SHOW_LOADING,
} from "../../redux";
import Loading from "../../components/Loading/Loading";
import { useRef } from "react";
import Newsletter from "../../components/NewsLetter/Newsletter";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ProductListing from "../../components/HomePage/ProductListing";

function Home(props) {
  const videoRef = useRef();
  const dispatch = useDispatch();
  const { homeData } = useSelector((state) => state.appReducer);
  const { isLoading } = useSelector((state) => state.errorReducer);

  useEffect(() => {
    window.scroll({ top: 0 });
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(GET_HOME_DATA));
  }, []);

  const settings = {
    autoplaySpeed: 2000,
    dots: true,
    speed: 3000,

    arrows: false,
    autoplay: true,
  };

  return (
    <>
      <SeoSetting title="Du-Afrik-Home" metaDescription="Home" />
      {homeData ? (
        <div className="home-page-main-wrap">
          <div className="home-slider">
            <Slider {...settings}>
              {homeData?.slider_images !== undefined &&
                homeData?.slider_images.map((item, id) => {
                  return (
                    <div className="slider-img-wrap">
                      <img src={item.image} alt={item?.title} />
                    </div>
                  );
                })}
            </Slider>
          </div>

          {homeData?.advertisement && homeData?.advertisement.length > 0 && (
            <section className="advertising-wrap">
              <Slider1
                title="Advertising"
                items={3}
                data={homeData?.advertisement}
              />
            </section>
          )}

          <ProductListing />

          {homeData?.best_selling_product?.data.length > 0 && (
            <section className="best-selling-wrap ">
              <div className="cm-container">
                <Slider3
                  title="Best Selling Products"
                  items={5}
                  data={homeData?.best_selling_product?.data}
                  firstItemBig={false}
                />
              </div>
            </section>
          )}
          <section className="video-banner">
            <div className="video-banner-inner ">
              <video controls muted>
                <source
                  src={homeData?.home_video?.video_banner}
                  type="video/mp4"
                />
              </video>
            </div>
          </section>
          {homeData?.latest_blog?.data.length > 0 && (
            <section className="blog-wrap">
              <div className="cm-container">
                <Slider4
                  title="Latest News & Blogs"
                  items={1}
                  data={homeData?.latest_blog?.data}
                  firstItemBig={true}
                />
              </div>
            </section>
          )}
          {/* {homeData?.best_selling_brands?.data.length > 0 && (
            <section className='best-selling-wrap '>
              <div className='cm-container'>
                <Slider3
                  title='Best Selling Brands'
                  items={3}
                  data={homeData?.best_selling_brands?.data}
                  firstItemBig={true}
                />
              </div>
            </section>
          )} */}
          {homeData?.brands?.data.length > 0 && (
            <section className="best-selling-wrap ">
              <div className="cm-container">
                <Slider3
                  title="Best selling brands"
                  items={4}
                  data={homeData?.brands?.data}
                  firstItemBig={false}
                />
              </div>
            </section>
          )}

          <section className="newsletter-wrap">
            <Newsletter />
          </section>
        </div>
      ) : (
        <Loading showLoading={isLoading} />
      )}
    </>
  );
}

export default Home;
