/** @format */

import React, { useEffect, useState } from "react";
import "./OrderManagement.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CANCEL_ORDER_BY_ORDER_ID,
  CLOSE_LOADING,
  globalAction,
  HIDE_MESSAGE,
  REMOVE_MESSAGE,
  SHOW_LOADING,
  SHOW_MESSAGE,
  FETCH_ORDER_REQUEST,
  UPDATE_ORDER_STATUS,
  UPDATE_SHIPPING_STATUS,
} from "../../../redux";
import { getOrderDetailByOrderId } from "../../../api";
import { Button, Modal } from "react-bootstrap";

function OrderDetails(props) {
  const dispatch = useDispatch();
  const { visibleMessage } = useSelector((state) => state.errorReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { orderInfo } = useSelector((state) => state.appReducer);
  const [cancelModalFlag, setCancelModalFlag] = useState(false);
  const [cancellationInput, setCancellationInput] = useState(
    "No reason given by customer"
  );
  const {
    match: { params },
  } = props;
  let orderId = params?.id;

  const [updateOrderData, setUpdateOrderData] = useState({ order_id: orderId });
  const [updateShippingData, setUpdateShippingData] = useState({
    order_id: orderId,
    shipping_provider: "N/A",
    shipping_url: "N/A",
    tracking_id: "N/A",
  });

  const CancelOrderModal = () => {
    return (
      <div className="cancel-order">
        <Modal
          show={cancelModalFlag}
          onHide={() => {
            setCancelModalFlag(!cancelModalFlag);
          }}
          backdrop="static"
          keyboard={true}
          className="cancel-order-inner"
        >
          <Modal.Header closeButton>
            <Modal.Title>Cancel Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure to Cancel this order..?
            <div className="cancel-reason-wrap">
              <label htmlFor="cancel-reason">
                Cancellation Reason<span>(optional)</span>
              </label>
              <textarea
                type="text"
                rows="5"
                className="input"
                onChange={(e) => {
                  setCancellationInput(e.target.value);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setCancelModalFlag(!cancelModalFlag);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                CancelOrder(orderId, cancellationInput);
              }}
            >
              Cancel Order
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const CancelOrder = (id, reason) => {
    let tempData = {
      id: id,
      cancellation_reason: reason,
    };
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(CANCEL_ORDER_BY_ORDER_ID, tempData));
    setCancellationInput("");
    setCancelModalFlag(false);
  };

  const handleOnChangeText = (e, fieldName, formType) => {
    if (formType === "orderUpdate") {
      setUpdateOrderData({ ...updateOrderData, [fieldName]: e.target.value });
    }
    if (formType === "shippingUpdate") {
      setUpdateShippingData({
        ...updateShippingData,
        [fieldName]: e.target.value,
      });
    }
  };

  const handleSubmitUpdateOrderStatus = async (e) => {
    e.preventDefault();
    if (updateOrderData.order_status) {
      await dispatch(globalAction(SHOW_LOADING));
      await dispatch(globalAction(UPDATE_ORDER_STATUS, updateOrderData));
      await dispatch(globalAction(UPDATE_SHIPPING_STATUS, updateShippingData));
      //setUpdateOrderData({order_id: orderId});
      //setOrderInfo(data.data);

      setCancelModalFlag(false);
    }
  };

  const UpdateOrderDetailsModal = () => {
    return (
      <div className="cancel-order update-order-info-wrap">
        <Modal
          show={cancelModalFlag}
          onHide={() => {
            setCancelModalFlag(!cancelModalFlag);
          }}
          backdrop="static"
          keyboard={true}
          className="cancel-order-inner update-order-info-wrap"
        >
          <Modal.Header closeButton>
            <Modal.Title>Update order details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form">
              <form
                onSubmit={(e) => {
                  handleSubmitUpdateOrderStatus(e);
                }}
              >
                <h4>Order Details</h4>
                <div className="form_field">
                  <label for="order_status">Order Status</label>
                  <select
                    className="input"
                    id="order_status"
                    required
                    onChange={(e) => {
                      handleOnChangeText(e, "order_status", "orderUpdate");
                    }}
                  >
                    <option>Select</option>
                    <option value="completed">Completed</option>
                    <option value="shipped">Shipped</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>

                {updateOrderData?.order_status === "cancelled" && (
                  <div className="form_field">
                    <label for="cancellation_reason">
                      Order Cancellation Reason
                    </label>
                    <textarea
                      className="input"
                      id="cancellation_reason"
                      onChange={(e) => {
                        handleOnChangeText(
                          e,
                          "cancellation_reason",
                          "orderUpdate"
                        );
                      }}
                    />
                  </div>
                )}

                <h4>Shipping Details</h4>
                <div className="form_field">
                  <label for="shipping_provider_name">
                    Shipping Provider Name
                  </label>
                  <input
                    className="input"
                    id="shipping_provider_name"
                    onChange={(e) => {
                      handleOnChangeText(
                        e,
                        "shipping_provider",
                        "shippingUpdate"
                      );
                    }}
                  />
                </div>
                <div className="form_field">
                  <label for="shipping_url">Shipping URL</label>
                  <input
                    className="input"
                    type="text"
                    id="shipping_url"
                    onChange={(e) => {
                      handleOnChangeText(e, "shipping_url", "shippingUpdate");
                    }}
                  />
                </div>
                <div className="form_field">
                  <label for="tracking_url">Shipping Tracking URL</label>
                  <input
                    className="input"
                    type="text"
                    id="tracking_url"
                    onChange={(e) => {
                      handleOnChangeText(e, "tracking_id", "shippingUpdate");
                    }}
                  />
                </div>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setCancelModalFlag(!cancelModalFlag);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="success">
                    Update
                  </Button>
                </Modal.Footer>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  useEffect(() => {
    dispatch(globalAction(SHOW_LOADING));
    dispatch(globalAction(FETCH_ORDER_REQUEST, orderId));
  }, []);

  return (
    <section
      className={`order_details ${
        orderInfo?.order_status === "cancelled" ? "cancelled" : ""
      }`}
    >
      <div className="page_title">
        <h3>Orders Details</h3>
      </div>
      <div className="box-size order_details_content">
        <div className="details_title">
          <ul>
            <li>Order Detail</li>
            <li>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`${orderInfo?.invoice_url}`);
                }}
              >
                Download Invoice
              </a>
            </li>
            {user?.role_id === 2 && (
              <li>
                {orderInfo?.order_status === "cancelled" ? (
                  <div className="cancel-watermark"> Cancelled </div>
                ) : (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setCancelModalFlag(!cancelModalFlag);
                    }}
                  >
                    Cancel Order
                  </a>
                )}
              </li>
            )}
            {user?.role_id === 3 && (
              <li>
                <a
                  className="update-order-info-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setCancelModalFlag(!cancelModalFlag);
                  }}
                >
                  Update Order Info
                </a>
              </li>
            )}
          </ul>
        </div>
        <div class="order_id">
          <ul>
            <li>
              <strong>Order Number</strong>{" "}
              <span>{orderInfo?.order_number}</span>
            </li>
            <li>
              <strong>Date &amp; Time</strong>{" "}
              <span>{orderInfo?.order_at}</span>
            </li>
            <li class="cancelled">
              <strong>Order Status</strong>{" "}
              <span>{orderInfo?.order_status}</span>
            </li>
            <li>
              <strong>Payment Method</strong>{" "}
              <span>
                {orderInfo?.payment_type === "cod"
                  ? "Pay on Delivery"
                  : orderInfo?.payment_type}
              </span>
            </li>
          </ul>
        </div>
        {orderInfo?.payment_type === "cod" && (
          <div class="notes">
            <p>
              Pay with cash upon delivery. if you choose{" "}
              <strong>pay on delivery</strong>
            </p>
          </div>
        )}

        <h3>ORDER DETAILS</h3>
        <div className="detail_table">
          <table>
            <tr>
              <th>Item</th>
              <th>Price/Unit</th>
              <th>Qty.</th>
              <th>Total</th>
            </tr>
            {orderInfo?.order_product?.length > 0 &&
              orderInfo?.order_product?.map((item, id) => {
                return (
                  <tr>
                    <td>
                      <Link to={`/product-detail/${item?.slug}`}>
                        {item?.product?.title}
                        <span>Product ID: {item?.product?.model_no}</span>
                      </Link>
                    </td>
                    <td>
                      <span>{item?.single_price}</span>
                      {/* <p>
                    <span> 2595.00</span>
                    <em>(Including Text)</em>
                  </p> */}
                    </td>

                    <td>{item?.qty}</td>
                    <td>{item?.total_price}</td>
                  </tr>
                );
              })}
          </table>
        </div>
        <ul className="bill_summery">
          <li>
            <strong>Total:</strong>
            <span>{Number(orderInfo?.order_price).toFixed(2)} $</span>
          </li>
          <li>
            <strong>Shipping Cost:</strong>
            <span>{Number(orderInfo?.shiping_fee).toFixed(2)} $</span>
          </li>
          {/* <li>
            <strong>Gst Charges:</strong>
            <span>1134.00</span>
          </li> */}

          <li>
            <strong>Grand Total:</strong>
            <strong>
              {(
                Number(orderInfo?.order_price) + Number(orderInfo?.shiping_fee)
              ).toFixed(2)}{" "}
              $
            </strong>
          </li>
        </ul>
        <div class="order_address">
          <h3>Shipping Address</h3>
          <ul class="billing_address">
            <li>{orderInfo?.shipping_addess_data?.full_name}</li>
            {orderInfo?.shipping_addess_data?.address_line_1 !== "null" && (
              <li>
                <strong>Address 1:</strong>
                {orderInfo?.shipping_addess_data?.address_line_1}
              </li>
            )}
            {orderInfo?.shipping_addess_data?.address_line_2 !== "null" && (
              <li>
                <strong>Address 2:</strong>
                {orderInfo?.shipping_addess_data?.address_line_2}
              </li>
            )}
            {orderInfo?.shipping_addess_data?.state !== "null" && (
              <li>
                <strong>State:</strong>
                {orderInfo?.shipping_addess_data?.state}
              </li>
            )}
            {orderInfo?.shipping_addess_data?.zip !== "null" && (
              <li>
                <strong>PostCode:</strong>
                {orderInfo?.shipping_addess_data?.zip}
              </li>
            )}
            {orderInfo?.shipping_addess_data?.country !== "null" && (
              <li>
                <strong>Country:</strong>
                {orderInfo?.shipping_addess_data?.country}
              </li>
            )}
            {orderInfo?.shipping_addess_data?.mobile_no !== "null" && (
              <li>
                <strong>Phone Number:</strong>
                {`+${orderInfo?.shipping_addess_data?.country_code}-${orderInfo?.shipping_addess_data?.mobile_no}`}
              </li>
            )}
          </ul>
          <h3>Billing Address</h3>
          <ul class="billing_address">
            <li>{orderInfo?.billing_address_data?.full_name}</li>
            {orderInfo?.billing_address_data?.address_line_1 !== "null" && (
              <li>
                <strong>Address 1:</strong>
                {orderInfo?.billing_address_data?.address_line_1}
              </li>
            )}
            {orderInfo?.billing_address_data?.address_line_2 !== "null" && (
              <li>
                <strong>Address 2:</strong>
                {orderInfo?.billing_address_data?.address_line_2}
              </li>
            )}
            {orderInfo?.billing_address_data?.state !== "null" && (
              <li>
                <strong>State:</strong>
                {orderInfo?.billing_address_data?.state}
              </li>
            )}
            {orderInfo?.billing_address_data?.zip !== "null" && (
              <li>
                <strong>PostCode:</strong>
                {orderInfo?.billing_address_data?.zip}
              </li>
            )}
            {orderInfo?.billing_address_data?.country !== "null" && (
              <li>
                <strong>Country:</strong>
                {orderInfo?.billing_address_data?.country}
              </li>
            )}
            {orderInfo?.billing_address_data?.mobile_no !== "null" && (
              <li>
                <strong>Phone Number:</strong>
                {`+${orderInfo?.billing_address_data?.country_code}-${orderInfo?.billing_address_data?.mobile_no}`}
              </li>
            )}
          </ul>
        </div>
      </div>
      {user?.role_id === 2 && CancelOrderModal()}
      {user?.role_id === 3 && UpdateOrderDetailsModal()}
    </section>
  );
}

export default OrderDetails;
