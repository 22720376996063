import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_PRODUCT_VARIANT_BY_ID,
  GET_PRODUCT_LIST,
  globalAction,
} from "../../../../../redux";
import tableIcons from "../../../../Dashboard/TableIcons";
import { GrView } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import "./ProductVariant.scss";
import { MdLibraryAdd } from "react-icons/md";
import AddProductVariant from "../AddProductVariant/AddProductVariant";
import { getProductListByVendorId } from "../../../../../api";
import axios from "axios";

function ProductVariant(props) {
  const { productList } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { productVariantList } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const [modalFlag, setModalFlag] = useState(false);
  const [productID, setProductID] = useState(undefined);

  // useEffect(() => {
  //   if (user.id) {
  //     dispatch(globalAction(GET_PRODUCT_LIST, user.id));
  //   }
  // }, []);

  const handleAddVariantModal = (e, productId) => {
    e.preventDefault();
    setModalFlag(!modalFlag);
    setProductID(productId);
  };
  const handleCloseAddVariantModal = () => {
    setModalFlag(!modalFlag);
  };
  const deleteVariant = (data) => {
    console.log(data, "data");
    const confrm = window.confirm("Are you sure to delete ?");
    if (!confrm) return;
    axios
      .post(
        `http://duafric-admin.devshs.com/api/delete_product_variant?id=${data?.id}`
      )
      .then((res) => {
        if (res?.status === 200) {
          window.location.reload();
        }
      })
      .catch((err) => alert(err));
  };

  const orderTitle = [
    { title: " ID", field: "id" },
    {
      title: "Image",
      field: "image_url",
      render: (rowData) => (
        <img
          src={rowData.image_url}
          style={{ width: 100, height: 100, borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Name",
      field: "title",
      render: (rowData) => <Link to={`/${rowData.id}`}>{rowData.title}</Link>,
    },
    { title: "Category", field: "category_name" },
    {
      title: "Price",
      field: productList.sale_price !== null ? "sale_price" : "regular_price",
    },
    { title: "QTY", field: "stock_qty" },
    {
      title: "QTY Status",
      field: "stock_availability",
      render: (rowData) =>
        rowData.stock_availability === "out_stock"
          ? "Out of Stock"
          : "In Stock",
    },
    {
      title: "Options",
      render: (rowData) => {
        return (
          <div className="menu-options">
            <ul>
              <li>
                <a
                  onClick={(e) => {
                    handleAddVariantModal(e, rowData.id);
                  }}
                >
                  <span className="icon">
                    <MdLibraryAdd />
                  </span>
                  <span className="text">Add variant</span>
                </a>
              </li>
              <li>
                <a
                  //   href={`/vendorDashboard/product-management/product-variants/105`}
                  href={`/vendorDashboard/product-management/update-product/${rowData.id}`}
                >
                  <span className="icon">
                    <GrView />
                  </span>
                  <span className="text">View all variants </span>
                </a>
              </li>
              {/* <li>
                <div onClick={() => deleteVariant(rowData)}>
                  <span className="icon">
                    <MdDelete size={20} />
                  </span>
                </div>
              </li> */}
            </ul>
          </div>
        );
      },
    },
  ];
  return (
    <div className="product-variant-wrap">
      <div className="pv-inner">
        <div className="title">
          <h3>Manage Products Variants</h3>
        </div>
        <div className="vd-dashboard-body box-size pv-content">
          <div className="pv-table">
            <MaterialTable
              title="All Variants Product"
              columns={orderTitle}
              icons={tableIcons}
              options={{
                search: true,
                // exportButton: true,
                pageSize: 10,
                pageSizeOptions: [5, 10, 20],
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: "No records to display",
                },
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let params = `?user_id=${user?.id}`;
                  params += `&page=${query.page + 1}`;
                  params += `&limit=${query.pageSize}`;
                  params += `&search=${query.search}`;
                  if (user) {
                    getProductListByVendorId(params).then((res) => {
                      let { data, status } = res;
                      if (status === 200) {
                        let filterData = data?.product?.data.filter(
                          (variant) => variant?.product_variant.length > 0
                        );
                        resolve({
                          data: filterData,
                          page: data?.product?.current_page - 1, // current page number
                          totalCount: data?.product?.total, // total row number
                        });
                      }
                    });
                  }
                })
              }
            />
          </div>
          <div className="pv-modal">
            <AddProductVariant
              modalFlag={modalFlag}
              handleClose={() => handleCloseAddVariantModal()}
              btnText={"Add Variant"}
              modalHeading={"Add new Variant"}
              userId={user?.id}
              productId={productID}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductVariant;
