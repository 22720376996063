import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./VendorStore.scss";
import Store1 from "../../assets/img/store1.jpg";
import ReactStars from "react-rating-stars-component";
import { FaShoppingCart } from "react-icons/fa";
import { IoIosHeart, IoLogoUsd } from "react-icons/io";
import ProductImg1 from "../../assets/img/product1.png";
import { getVendorDetailsByIdApi } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { ADD_PRODUCT_TO_WISHLIST, ADD_TO_CART, CLOSE_LOADING, GET_PRODUCT_LIST, globalAction, SHOW_LOADING } from "../../redux";
import Loading from "../../components/Loading/Loading";
import { Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import emptyProduct from '../../assets/img/emptyProduct.png'

function VendorStore(props) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.errorReducer)
  const { user } = useSelector(state => state.authReducer)
  const { productList } = useSelector(state => state.appReducer)
  const [vendorData, setVendorData] = useState(undefined);

  const {
    match: { params },
  } = props;
  let vendorId = params?.id;
  // console.log(props);

  useEffect(() => {
    window.scroll({ top: 0 });
    dispatch(globalAction(SHOW_LOADING));
    getVendorDetailsByIdApi(vendorId)
      .then(async (res) => {
        dispatch(globalAction(CLOSE_LOADING));
        const { status, data } = res;
        if (status === 200) {
          await setVendorData(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(globalAction(CLOSE_LOADING));
      });


    if (vendorId) {
      let params = `?user_id=${vendorId}`
      dispatch(globalAction(GET_PRODUCT_LIST, params))
    }

  }, []);



  const addToCart = (item, itemQty) => {
    dispatch(globalAction(SHOW_LOADING))
    item['qty'] = itemQty || 1
    dispatch(globalAction(ADD_TO_CART, { item: item, user: user }))
  }

  const addToWishlist = (productId) => {
    if (user) {
      dispatch(globalAction(SHOW_LOADING))
      dispatch(globalAction(ADD_PRODUCT_TO_WISHLIST, { product_id: productId, user_id: user?.id }))
    }
    else {
      alert('Please Login with customer account')
    }
  }

  const handlePageClick = (data) => {
    document.getElementById("card-wrap").scrollIntoView(true);
    dispatch(globalAction(SHOW_LOADING));
    let params = `?user_id=${vendorId}&page=${data.selected + 1}`
    dispatch(globalAction(GET_PRODUCT_LIST, params))
  };
  // console.log("vendorData,", productList);

  return (
    <div className="vender_store">
      <Loading
        showLoading={isLoading}

      />


      <div className="banner">
        <div
          className="image"
          style={{
            backgroundImage: `url(${vendorData?.store_banner || Store1})`,
          }}
        >
          {/* <img src={Store1} alt="Vendor Name" /> */}
          {/* <Link to="#"></Link> */}
        </div>
      </div>
      {productList?.data?.length>0
        ?
        <>
          <div className="_store_content">
            <div className="container">
              <div className="card_wrap" id='card-wrap'>
                {productList?.data &&
                  productList?.data?.map((item, i) => {
                    console.log(item);
                    return (
                      <div className="card_content">
                        <div className="content-wrap-inner">
                          <div className="img_wrap">
                            <Link to={`/product-detail/${item?.slug}`}>
                              <img src={item?.image_url} alt={item?.title} />
                            </Link>
                          </div>
                          <div className="pc-content">
                            <Link to={`/product-detail/${item?.slug}`}>
                              <h3 className="title">{item?.title}</h3>
                            </Link>
                            {item?.sale_price !== null ?
                              <p>
                                <span>
                                  {item?.sale_price}
                                  <IoLogoUsd />
                                </span>
                                <span>
                                  <del>
                                    {item?.regular_price}
                                    <IoLogoUsd />
                                  </del>
                                </span>
                              </p>
                              :
                              <p>
                                <span>
                                  {item?.regular_price}
                                  <IoLogoUsd />
                                </span>
                              </p>

                            }


                            <Link to={`/vendor-store/${item?.user?.id}`}>
                              <p className="sold_by">
                                <small>Sold by: </small>
                                {item?.user?.name}
                              </p>
                            </Link>
                            <div className="pc-review">
                              <span className="star-icon">
                                <ReactStars
                                  count={5}
                                  size={24}
                                  isHalf={true}
                                  edit={false}
                                  value={item?.avg_rating || 0}
                                  emptyIcon={<i className="far fa-star"></i>}
                                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                                  fullIcon={<i className="fa fa-star"></i>}
                                  activeColor="#ffd700"
                                />
                              </span>
                              <span className="review-count">({item?.product_review_count} Reviews)</span>
                            </div>
                          </div>
                          <div className="button">
                            <button className="btn cart">
                              <FaShoppingCart
                                onClick={() => addToCart(item, item?.minimum_qty_per_order)}
                              />
                            </button>
                            <button className="btn wish">
                              <IoIosHeart
                                onClick={() => { addToWishlist(item?.id) }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="view-all-wrap">
            <div className="view-all-wrap-inner cm-container">

              <div className="view-all-pagination-wrap">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(productList?.total / productList?.per_page)} marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"} pageLinkClassName={"page-link"} previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"} forcePage={Number(productList?.current_page - 1)} />
              </div>


            </div>
          </div>
        </>
        :
        <div className="no-product-found">
          {/* <img src={emptyProduct} alt="NO product found" srcset="" /> */}
          <h1>No product found...!</h1>
        </div>
      }
    </div >
  );
}

export default VendorStore;
