import React, { useEffect, useState } from 'react'
import { IoIosStats } from 'react-icons/io';
import { MdAttachMoney, MdHighlightOff } from 'react-icons/md';
import { FiShoppingBag } from 'react-icons/fi';
import { RiTruckLine } from 'react-icons/ri';
import { BsCart2 } from 'react-icons/bs';
import './DashboardSummary.scss';
import { Chart } from "react-google-charts";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_LOADING_VENDOR, globalAction, SHOW_LOADING_VENDOR , REQUEST_VENDOR_PAYMENT_GRAPH} from '../../../../redux';
import { getVendorDashboardDetailsApi } from '../../../../api';
import parse from 'html-react-parser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from "../../../../components/Loading/Loading";

function DashboardSummary() {
    const dispatch = useDispatch()
    const [dashboardData, setDashboardData] = useState(undefined)
    const { user } = useSelector(state => state.authReducer);
    const { vendorPayments } = useSelector(state => state.appReducer);
    const { isLoadingVendor } = useSelector((state) => state.errorReducer);
    const [orderGraph, setOrderGraph] = useState([]);


    useEffect(() => {
        if (user?.id) {
           dispatch(globalAction(SHOW_LOADING_VENDOR))
            getVendorDashboardDetailsApi({ vendor_id: user?.id })
                .then((res) => {
                    const { status, data } = res
                    if (status === 200) {
                        setDashboardData(data);
                        let tempOrderGraph = [['Months', 'Orders']];
                        data?.dates?.forEach((item, i) => {
                            let numberOfOrderBooked = data?.order_graph_last_12_months?.find((item2) => item2.months === item.month)?.total // get number Of order booked in  given month
                            tempOrderGraph.push([item?.month, numberOfOrderBooked || 0])
                        });

                        // moveElement(tempOrderGraph , 5, 1)
                        // moveElement(tempOrderGraph , 6, 2)
                        // moveElement(tempOrderGraph , 7, 3)
                        // moveElement(tempOrderGraph , 8, 4)
                        // moveElement(tempOrderGraph , 9, 5)
                        // moveElement(tempOrderGraph , 10, 6)
                        // moveElement(tempOrderGraph , 11, 7)
                        // moveElement(tempOrderGraph , 12, 8)

                        setOrderGraph(tempOrderGraph);
                        dispatch(globalAction(REQUEST_VENDOR_PAYMENT_GRAPH, user?.id));
                    }
                })
                .catch((err) => {
                    alert('something went wrong. Please try again')
                })
        }
    }, [])

    const moveElement = (input, from, to) => {
        let numberOfDeletedElm = 1;
        const elm = input.splice(from, numberOfDeletedElm)[0];
        numberOfDeletedElm = 0;
        input.splice(to, numberOfDeletedElm, elm);
      }


    return (
        <div className="vendor-dashboard-summary-warp">
            <div className="vds-inner-warp">
                <div className="vds-top-section">
                    <h1>Dashboard</h1>
                    <Loading showLoading={isLoadingVendor} />
                </div>
                <div className="vds-row-2 clearfix">
                    <div className="vds-item products">
                        <Link to='/vendorDashboard/product-management'> 
                            <div className="vds-item-content">
                                <span>
                                    {dashboardData?.count_product}
                                </span>
                                <p>Total Products</p>
                            </div>
                            <div className="vds-item-icon ">
                                <FiShoppingBag />
                            </div>
                        </Link>
                    </div>

                    <div className="vds-item orders">
                        <Link to='/vendorDashboard/order-management'> 
                            <div className="vds-item-content">
                                <span>
                                    {dashboardData?.count_orders}
                                </span>
                                <p>Total Orders</p>
                            </div>
                            <div className="vds-item-icon">
                                <RiTruckLine />
                            </div>
                        </Link>
                    </div>

                    <div className="vds-item total-earning">
                            <div className="vds-item-content">
                                <span>
                                    ${dashboardData?.user_details?.wallet_amount || 0}
                                </span>
                                <p>Wallet Balance</p>
                            </div>
                            <div className="vds-item-icon">
                                <MdAttachMoney />
                            </div>
                    </div>
                </div>

                <Container>
                    <Row>
                        <Col>                    
                            <div className="vds-row-inner">
                                <div className="vds-row-heading">
                                    <h2 style={{"font-size":"18px"}}>Total Order in 2021</h2>
                                </div>
                                <div className="vds-row-chats">
                                    <Chart
                                        width={'100%'}
                                        height={'400px'}
                                        chartType="Bar"
                                        loader={<div>Loading Chart</div>}
                                        data={orderGraph}
                                        options={{
                                            hAxis: { title: 'Months', minValue: 0, maxValue: 15 },
                                            vAxis: { title: 'Orders', minValue: 0, maxValue: 15 },
                                            legend: 'none',
                                            animation: {
                                                startup: true,
                                                easing: 'linear',
                                                duration: 1500,
                                            },
                                            enableInteractivity: false,
                                        }}
                                        chartEvents={[
                                            {
                                                eventName: 'animationfinish',
                                                callback: () => {
                                                },
                                            },
                                        ]}
                                        rootProps={{ 'data-testid': '2' }}
                                    />
                                </div>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col><div className="vds-row-inner">
                        <div className="vds-row-heading">
                            <h2 style={{"font-size":"18px"}}>Received Payout in 2021</h2>
                        </div>
                        <div className="vds-row-chats">
                            <Chart
                                width={'100%'}
                                height={'400px'}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={vendorPayments}
                                options={{
                                    hAxis: { title: 'Months', minValue: 0, maxValue: 15 },
                                    vAxis: { title: 'Payout', minValue: 0, maxValue: 15 },
                                    legend: 'none',
                                    animation: {
                                        startup: true,
                                        easing: 'linear',
                                        duration: 1500,
                                    },
                                    enableInteractivity: false,
                                }}
                                chartEvents={[
                                    {
                                        eventName: 'animationfinish',
                                        callback: () => {
                                            // console.log('Animation Finished')
                                        },
                                    },
                                ]}
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </div>
                    </div></Col>
                    </Row>
                </Container>



                {/* <div className="vds-row-3">
                    <div className="vds-row-inner">
                        <div className="vds-row-heading">
                            <h2>Total Order in 2021

                            </h2>
                        </div>
                        <div className="vds-row-chats">
                            <Chart
                                width={'100%'}
                                height={'400px'}
                                chartType="Bar"
                                loader={<div>Loading Chart</div>}
                                // data={[
                                //     ['x', 'dogs'],
                                //     [0, 10],
                                //     [1, 10],
                                //     [2, 23],
                                //     [3, 17],
                                //     [4, 18],
                                //     [5, 9],
                                //     [6, 11],
                                //     [7, 27],
                                //     [8, 33],
                                //     [9, 40],
                                //     [10, 32],
                                //     [11, 35],
                                // ]}
                                data={orderGraph}
                                options={{
                                    hAxis: { title: 'Months', minValue: 0, maxValue: 15 },
                                    vAxis: { title: 'Orders', minValue: 0, maxValue: 15 },
                                    legend: 'none',
                                    animation: {
                                        startup: true,
                                        easing: 'linear',
                                        duration: 1500,
                                    },
                                    enableInteractivity: false,
                                }}
                                chartEvents={[
                                    {
                                        eventName: 'animationfinish',
                                        callback: () => {
                                        },
                                    },
                                ]}
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </div>
                    </div>
                </div> */}

                <div className="vds-row-4">
                    <div className="vds-row-4-inner">
                        <div className="vds-row-4-heading">
                            <h2>Latest Order <Link
                                to='/vendorDashboard/order-management'
                            > <small><em>(View all)</em></small>
                            </Link></h2>
                        </div>
                        <div className="vds-row-4-orders">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order ID</th>
                                        <th>Customer Name</th>
                                        {/* <th>QTY</th> */}
                                        <th>Price</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dashboardData?.orders?.data &&
                                        dashboardData?.orders?.data.map((item, i) => {
                                            const { created_at, order_number, user, id, order_price } = item
                                            return (
                                                <tr key={created_at}>
                                                    <td></td>
                                                    <td><Link to={`/vendorDashboard/order-detail/${id}`}> {order_number}</Link></td>
                                                    <td>{user?.name}</td>
                                                    {/* <td>5</td> */}
                                                    <td>${Number(order_price).toFixed(2)}</td>
                                                    <td>{created_at}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>

                {/* <div className="vds-row-3 payout-wrap">
                    <div className="vds-row-inner">
                        <div className="vds-row-heading">
                            <h2>Received Payout in 2021</h2>
                        </div>
                        <div className="vds-row-chats">
                            <Chart
                                width={'100%'}
                                height={'400px'}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={[
                                    ['x', 'dogs'],
                                    [0, 0],
                                    [1, 10],
                                    [2, 23],
                                    [3, 17],
                                    [4, 18],
                                    [5, 9],
                                    [6, 11],
                                    [7, 27],
                                    [8, 33],
                                    [9, 40],
                                    [10, 32],
                                    [11, 35],
                                ]}
                                options={{
                                    hAxis: { title: 'Months', minValue: 0, maxValue: 15 },
                                    vAxis: { title: 'Payout', minValue: 0, maxValue: 15 },
                                    legend: 'none',
                                    animation: {
                                        startup: true,
                                        easing: 'linear',
                                        duration: 1500,
                                    },
                                    enableInteractivity: false,
                                }}
                                chartEvents={[
                                    {
                                        eventName: 'animationfinish',
                                        callback: () => {
                                            // console.log('Animation Finished')
                                        },
                                    },
                                ]}
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </div>
                    </div>
                </div> */}

                {/* <div className="vds-row-6">
                    <div className="vds-row-6-inner">
                        <div className="vds-row-6-heading">
                            <h2>Recently Added Products

                            </h2>
                        </div>
                        <div className="vds-row-content">
                            <div className="vds-recent-products">
                                {dashboardData?.products &&
                                    dashboardData?.products?.data.map((item, i) => {
                                        const { slug, created_at, title, image_url, id, brand_name, model_no, category_name, tags, sub_category_name, description, regular_price, sale_price } = item
                                        return (
                                            <div className="vds-item" key={created_at}>
                                                <div className="vds-rap-img">
                                                    <Link to={`/product-detail/${slug}`}>
                                                        <img src={image_url} alt={title} />
                                                    </Link>
                                                </div>
                                                <div className="vds-rap-content">
                                                    <Link to={`/product-detail/${slug}`}>
                                                        {title}
                                                    </Link>
                                                    <p>{parse(description.slice(0, 100) || '')}</p>
                                                    <div className="product-meta">
                                                        <p><span>Brand : </span>{brand_name}</p>
                                                        <p><span>Model Number : </span> {model_no}</p>
                                                        <p><span>Category : </span> {category_name} / {sub_category_name}</p>
                                                        <p><span>Tags : </span><strong>{tags.split(', ').reduce((acc, item) => {
                                                            return acc = acc + ' #' + item;
                                                        }, '')}
                                                        </strong></p>
                                                    </div>
                                                </div>
                                                <div className="vds-rap-price">
                                                    {sale_price !== null ?
                                                        <>
                                                            <span>
                                                                ${sale_price}
                                                            </span>
                                                            <span>
                                                                ${regular_price}
                                                            </span>
                                                        </>
                                                        :
                                                        <span>
                                                            ${regular_price}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }




                            </div>
                            <div className="vds-order-distribution">

                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default DashboardSummary
