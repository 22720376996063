import React from "react";
import "./productListing.scss";
import {
  ADD_PRODUCT_TO_WISHLIST,
  ADD_TO_CART,
  SHOW_LOADING,
  globalAction,
} from "../../redux";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosHeart } from "react-icons/io";
import { FaShoppingCart } from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
import productDemoImg from "../../assets/img/productDemo.jpg";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Apis } from "../../api";

const ProductListing = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    axios
      .get(`${Apis?.baseUrl}${Apis?.mid}${Apis?.product_listing}?limit=15`)
      .then((res) => {
        setProductList(res?.data?.product_list);
      });
  }, []);

  const addToCart = (item, itemQty) => {
    dispatch(globalAction(SHOW_LOADING));
    item["qty"] = itemQty || 1;
    dispatch(globalAction(ADD_TO_CART, { item: item, user: user }));
  };
  const addToWishlist = (productId) => {
    if (user) {
      dispatch(globalAction(SHOW_LOADING));
      dispatch(
        globalAction(ADD_PRODUCT_TO_WISHLIST, {
          product_id: productId,
          user_id: user?.id,
        })
      );
    } else {
      alert("Please Login with customer account");
    }
  };

  return (
    <>
      <h2 style={{ textAlign: "center", marginTop: 15 }}>LATEST PRODUCTS</h2>
      <div className="product_card">
        {productList?.data?.length > 0 &&
          productList?.data.map((item, index) => (
            <div className="card_content">
              {item?.stock_qty < item?.minimum_qty_per_order && (
                <div className="out-of-stock">
                  <span>Out of Stock</span>
                </div>
              )}
              {item.product_variant.length > 0 ? (
                <a href={`/product-detail/${item.slug}`}>
                  <img
                    src={item?.product_variant[0].image || productDemoImg}
                    alt={item.title}
                  />
                </a>
              ) : (
                <a href={`/product-detail/${item.slug}`}>
                  <img
                    src={item.image_url || productDemoImg}
                    alt={item.title}
                  />
                </a>
              )}
              <div className="pc-content">
                <a href={`/product-detail/${item.slug}`}>
                  <h3 className="title">{item.title}</h3>
                </a>

                {item?.sale_price !== null ? (
                  <p>
                    <span className="saleprice">{item?.sale_price}$</span>
                    <del className="regular-price">
                      {item && item.regular_price != item.sale_price
                        ? item.regular_price + "$"
                        : ""}
                    </del>
                  </p>
                ) : (
                  <p>
                    <span className="saleprice">{item?.regular_price} $</span>
                  </p>
                )}
                {item?.user?.company_name ? (
                  <a href={`/vendor-store/${item?.user?.id}`}>
                    <p className="sold_by">
                      <small>Sold by: </small>
                      {item?.user?.company_name}
                    </p>
                  </a>
                ) : (
                  <a href={`/`}>
                    <p className="sold_by">
                      <small>Sold by: </small>
                      Du-Afrik
                    </p>
                  </a>
                )}

                <div>
                  <p className="sold_by">
                    <small>Brand: </small>
                    {item.brand_name}
                  </p>
                </div>
                <div>
                  <p className="sold_by">
                    <small>M.no : {item.model_no}</small>
                  </p>
                </div>
                <div className="pc-review">
                  <span className="star-icon">
                    <ReactStars
                      count={5}
                      size={24}
                      isHalf={true}
                      edit={false}
                      value={item?.avg_rating || 0}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ffd700"
                    />
                  </span>
                  <span className="review-count">
                    ({item?.product_review_count || 0} Reviews)
                  </span>
                  <div className="button">
                    {item?.stock_qty > item?.minimum_qty_per_order && (
                      <button
                        className="btn cart"
                        onClick={() =>
                          addToCart(item, item?.minimum_qty_per_order)
                        }
                      >
                        <FaShoppingCart />
                      </button>
                    )}
                    <button
                      className="btn wish"
                      onClick={() => {
                        addToWishlist(item?.id);
                      }}
                    >
                      <IoIosHeart />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div style={{ textAlign: "center", marginTop: 10, marginBottom: 15 }}>
        <Link to={`/products`} className="view-all-btn">
          View All
        </Link>
      </div>
    </>
  );
};

export default ProductListing;
