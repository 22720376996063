import React, { useEffect, useState } from 'react';
import './Address.scss';
import addAddressImg from '../../assets/img/golden-logo.png'
import AddressModal from './AddressModal'
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_ADDRESS_BY_ADDRESS_ID, GET_ADDRESS_LIST, globalAction, SHOW_LOADING } from '../../redux';
import { GiCheckMark } from 'react-icons/gi';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin2Line } from 'react-icons/ri';

function SelectAddress(props) {
    const { savedAddressList } = useSelector(state => state.appReducer)
    const { user } = useSelector(state => state.authReducer)
    const [isUpdateModal, setIsUpdateModal] = useState(false)
    const [dataForUpdate, setdataForUpdate] = useState(undefined)
    const [modalVisible, setModalVisible] = useState(false)
    const [ShippingInfo, setShippingInfo] = useState({ billing_address: undefined, shipping_addess: undefined })
    const dispatch = useDispatch()



    const modalToggleHandle = (typeOfForm) => {
        if (typeOfForm === 'add-new') {
            setdataForUpdate(undefined)
            setIsUpdateModal(false)
        }
        setModalVisible(!modalVisible)
    }
    useEffect(() => {
        if (user) {
            dispatch(globalAction(SHOW_LOADING))
            dispatch(globalAction(GET_ADDRESS_LIST, user?.id))
        }
    }, [])

  useEffect(() => {
    if (savedAddressList) {
        savedAddressList.find((item, i) => {
            if (item.default_address === 'yes') {
                setShippingInfo({ ...ShippingInfo, shipping_addess: item, billing_address: item })
            }
        })
    }
  }, [savedAddressList])



    const handleAddressDelete = (id) => {
        dispatch(globalAction(SHOW_LOADING))
        dispatch(globalAction(DELETE_ADDRESS_BY_ADDRESS_ID, { id: id, user: user }))
    }

    const handleUpdateModal = (item) => {
        setdataForUpdate(item)
        setIsUpdateModal(true)
        modalToggleHandle();
    }

    const handleShippingInfo = (e, typeOfAddress, address) => {
        if (typeOfAddress === 'shipping_addess') {
            setShippingInfo({ ...ShippingInfo, shipping_addess: address })
        }
        else if (typeOfAddress === 'billing_address') {
            setShippingInfo({ ...ShippingInfo, billing_address: address })
        }
        else {

        }

    }

    const handleSubmit=()=>{
        localStorage.setItem('ShippingInfo',JSON.stringify(ShippingInfo))
        props.history.push('/order-summary')
    }


    return (

        <div className='select-address-wrap'>
            <div className="sd-inner cm-container">
                <div className="title">
                    <h1>Select Delivery/Billing Addresses</h1>
                </div>
                <div className="sd-listing clearfix">
                    <div className="sd-list-item add-new"
                        onClick={() => { modalToggleHandle('add-new') }}
                    >
                        <img src={addAddressImg} alt='Add Address' srcset="" />
                        <h2>
                            +    Add address
                        </h2>
                    </div>

                    {savedAddressList &&
                        savedAddressList.map((item, i) => {
                            const { id, full_name, created_at, state_data, country_data, zip, address_line_1, address_line_2, mobile_no, default_address } = item

                            return (
                                <div key={created_at} className="sd-list-item ">
                                    <h6>{full_name}</h6>
                                    <p>{`${address_line_1}, ${state_data?.name}(${zip}), ${country_data?.name}`}</p>
                                    {address_line_2 && <p>{`${address_line_2}, ${state_data?.name}(${zip}), ${country_data?.name}`} <em>(Alternate Address)</em></p>}
                                    <p>{`+${country_data?.phonecode}-${mobile_no}`}</p>
                                    <p></p>
                                    <div className="addressChoice">
                                        <div className='addressChoice-item'>
                                            <input
                                                type="radio"
                                                name='forDelivery'
                                                id={`forDelivery-${i}`}
                                                onClick={(e) => handleShippingInfo(e, 'shipping_addess', item)}
                                                checked={ShippingInfo?.shipping_addess?.id === item.id}
                                                defaultChecked={default_address === 'yes'}
                                            />
                                            <label htmlFor={`forDelivery-${i}`}>
                                                <span><GiCheckMark /></span>
                                                Delivery Address </label>
                                        </div>
                                        <div className='addressChoice-item'>
                                            <input
                                                type="radio"
                                                name='forbilling'
                                                id={`forbilling-${i}`}
                                                onClick={(e) => handleShippingInfo(e, 'billing_address', item)}
                                                checked={ShippingInfo?.billing_address?.id === item.id}
                                            />
                                            <label
                                                htmlFor={`forbilling-${i}`}
                                            >
                                                <span><GiCheckMark /></span>
                                                Billing Address </label>
                                        </div>
                                    </div>
                                    <div className="address-actions">
                                        <span
                                            className="edit"
                                            onClick={() => { handleUpdateModal(item) }}
                                        >
                                            <FiEdit />
                                            Edit
                                        </span>
                                        <span
                                            className="delete"
                                            onClick={() => { handleAddressDelete(id) }}
                                        >
                                            <RiDeleteBin2Line />
                                            Delete
                                        </span>

                                    </div>

                                </div>
                            )
                        })

                    }



                </div>
                {ShippingInfo.shipping_addess &&
                    <div className="asw-button-wrap">
                        <button
                        onClick={()=>{handleSubmit()}}
                            className="btn-black"
                        >
                            Deliver on this address
                        </button>
                    </div>
                }


                <AddressModal
                    dataForUpdate={dataForUpdate}
                    isUpdateModal={isUpdateModal}
                    modalVisible={modalVisible}
                    modalToggleHandle={modalToggleHandle}

                />
            </div>

        </div>

    )
}

export default SelectAddress
