import React from 'react'
import { Alert } from 'react-bootstrap';
import './CmAlert.scss'

function CmAlert(props) {
  const { message, status, showAlert } = props
  return (
    showAlert &&
    <div className="alert-wrap">
      <Alert variant={status === 'success' ? 'success' : 'danger'}  >
        <p>
          {message}
        </p>
      </Alert>
    </div>
  )
}

export default CmAlert
