import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Signup.scss';
import { Form, Button, Dropdown, ButtonGroup } from 'react-bootstrap'
import LoginImg from '../../assets/img/Login-img.png'
import AllCountry from '../../Utilis/allCountry.json'

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { globalAction, SHOW_LOADING, SIGN_UP } from '../../redux';
import Loading from '../../components/Loading/Loading';
import CmAlert from '../../components/CmAlert/CmAlert';
import axios from 'axios';
import { getAdvertisementListApi } from '../../api';
import Slider from 'react-slick';
import SeoSetting from '../../components/SeoSetting/SeoSetting';




// https://ipwhois.app/json/
const credential = {
    name: '',
    email: '',
    password: '',
    psw_repeat: '',
    mobile: '',
    company_name: '',
    company_url: '',
}

function Signup() {
    let history = useHistory();
    const dispatch = useDispatch()
    const { visibleMessage, isLoading, status, message } = useSelector(state => state.errorReducer)
    const [customerForm, setcustomerForm] = useState('customer');
    const [state, setState] = useState(credential)
    const [currentCounrty, setCurrentCounrty] = useState(undefined)
    const [currentCountryInfo, setCurrentCountryInfo] = useState('')
    const [advertisementList, setAdvertisementList] = useState(undefined)
    const [validated, setValidated] = useState(false);



    const changeText = (value, text, selectCountry) => {
        let credential = state;
        credential[text] = value
        setState(credential)
        if (selectCountry) {
            setCurrentCountryInfo(selectCountry)
            setState({ ...state, selectCountry: selectCountry?.name, country_id: selectCountry?.id })
        }
    }

    const fetchCurrentCountry = async () => {
        await axios
            .get('http://www.geoplugin.net/json.gp')
            .then(async (res) => {
                console.log(res)
                await setCurrentCounrty(res.data.geoplugin_countryName)
                return (res.data.geoplugin_countryName)
                //  console(window.geoplugin_countryName())
            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        let tempSelectCountry;
        if (currentCounrty === undefined) {
            fetchCurrentCountry()
        }
        if (currentCounrty) {
            tempSelectCountry = AllCountry.find((countryItem) => countryItem?.name.toLowerCase() === currentCounrty.toLowerCase())
        }
        setCurrentCountryInfo(tempSelectCountry)
        setState({ ...state, selectCountry: tempSelectCountry?.name, country_id: tempSelectCountry?.id })

    }, [currentCounrty])


    const handleSubmit = (event, userType) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
          if (userType === "vendor") {
            let formData = { ...state, role_id: 3, history: history };
            dispatch(globalAction(SHOW_LOADING));
            dispatch(globalAction(SIGN_UP, formData));
          }
          if (userType === "customer") {
            let formData = { ...state, role_id: 2, history: history };
            dispatch(globalAction(SHOW_LOADING));
            dispatch(globalAction(SIGN_UP, formData));
          }
        }
        setValidated(true);
    };


    useEffect(() => {
        window.scroll({ top: 0 })
        getAdvertisementListApi('?page=register')
            .then((res) => {
                const { status, data } = res
                if (status === 200) {
                    setAdvertisementList(data.advertisement)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };



    return (
        <>
           <SeoSetting title='Du-Afrik-SignUp' metaDescription='Home' />
            <Loading
                showLoading={isLoading}
            />
            <CmAlert
                message={message}
                status={status}
                showAlert={visibleMessage}
            />
            <div className="signup-m-wrap">
                <div className="cm-container">
                    <div className="row">
                        <div className="col-md-6">

                            <div className="signup-form-wrap">
                                <div className="top-text">
                                    <h1>Register</h1>
                                    <p>Already have an account ? <Link  style={{"font-size":"21px"}} to="/login">Login </Link></p>
                                </div>
                               
                                {customerForm
                                    ?
                                    <div className="signup-form customer">
                                        <Form noValidate validated={validated}

                                            onSubmit={(e) => { handleSubmit(e, 'customer') }}>
                                            <Form.Group className="mb-1" controlId="formBasicEmail">
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control required
                                                    type="email"
                                                    placeholder="Enter email"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'email')} 
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your email address.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-1" controlId="formBasicEmail">
                                                <Form.Label>Mobile Number</Form.Label>
                                                <Form.Control required
                                                    type="number"
                                                    placeholder="Mobile number"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'mobile')} 
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your mobile number.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-1" controlId="formBasicEmail">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control required
                                                    type="text"
                                                    placeholder="Enter full name"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'name')} 
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your mobile number.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-1" controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control required
                                                    type="password"
                                                    placeholder="Enter 6 characters or more"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'password')}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your password.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-1" controlId="formBasicComfirmPassword">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control required
                                                    type="password"
                                                    placeholder="Enter 6 characters or more"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'psw_repeat')}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your confirm password.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-1" controlId="formBasicCheckbox">
                                            <Form.Label>I agree to the Du-Afrik <Link to='/Terms-and-Condtions'>Terms and Conditions</Link> </Form.Label><br/>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    required 
                                                    feedback="You must agree before submitting."
                                                    feedbackType="invalid"
                                                    className="form-check" 
                                                />
                                            </Form.Group>
                                            <Button variant="primary" type="submit" className="btn-brown">Sign Up</Button>
                                        </Form>
                                    </div>
                                    :
                                    <div className="signup-form vendor">
                                        <Form onSubmit={(e) => { handleSubmit(e, 'vendor') }}>

                                            <Form.Group className="mb-5 select-country-input" controlId="formselectCountry">
                                                <Form.Label>Select Country</Form.Label>
                                                <Dropdown as={ButtonGroup} defaultValue="india">
                                                    <Dropdown.Toggle
                                                        id="dropdown-custom-1"
                                                        className='input'
                                                        title="Select Country"
                                                    >

                                                        <span><img src={currentCountryInfo?.flag || ''} alt={currentCountryInfo?.name} /> {currentCountryInfo?.name || 'Select country'}(+{currentCountryInfo?.phonecode})</span>

                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="super-colors" >
                                                        {AllCountry.map((countryItem, i) => {
                                                            const { name, phonecode, id, flag } = countryItem;
                                                            if (flag !== 'Null') {
                                                                return (
                                                                    <Dropdown.Item eventKey="1" key={id}
                                                                        onClick={(e) => { changeText(name, 'selectCountry', countryItem) }}>
                                                                        <span><img src={flag} alt={name} /> {`${name}(+${phonecode})`}</span>
                                                                    </Dropdown.Item>
                                                                )
                                                            }
                                                        })}

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {/* <Form.Control required type="companyName" 
                                        defaultValue={currentUserInfo?.country} 
                                        readOnly={true} 
                                        placeholder="Select Country" 
                                        className="input" /> */}

                                            </Form.Group>

                                            <Form.Group className="mb-5" controlId="formBasicEmail">
                                                <Form.Label>Full Name</Form.Label>
                                                <Form.Control required
                                                    type="text"
                                                    placeholder="Enter full name"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'name')} />
                                            </Form.Group>

                                            <Form.Group className="mb-5" controlId="formBasicCompany">
                                                <Form.Label>Company Name</Form.Label>
                                                <Form.Control
                                                    required type="companyName"
                                                    placeholder="Enter company name"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'company_name')} />

                                            </Form.Group>

                                            <Form.Group className="mb-5" controlId="formBasicEmail">
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control required
                                                    type="email"
                                                    placeholder="Enter email"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'email')} />
                                            </Form.Group>
                                            <Form.Group className="mb-5" controlId="formBasicEmail">
                                                <Form.Label>Mobile Number</Form.Label>
                                                <Form.Control required
                                                    type="number"
                                                    placeholder="Mobile number"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'mobile')} />
                                            </Form.Group>

                                            <Form.Group className="mb-5" controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control required
                                                    type="password"
                                                    placeholder="Enter 6 character or more"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'password')}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-4" controlId="formBasicComfirmPassword">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control required
                                                    type="password"
                                                    placeholder="Enter 6 character or more"
                                                    className="input"
                                                    onChange={(e) => changeText(e.target.value, 'psw_repeat')}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check required type="checkbox" label="" className="form-check" />
                                                <Form.Label>I agree to the Du Afric <Link to='/terms-and-condition'> Terms & Condition</Link> </Form.Label>
                                            </Form.Group>
                                            <Button variant="primary" type="submit" className="btn-brown">
                                                Sign Up
                                            </Button>
                                        </Form>
                                    </div>
                                }




                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* <div className="img-wrap">
                                <img src={LoginImg} alt='Du-Afric Login Page' />
                            </div> */}

                            <div className="slider">
                                <Slider {...settings}>
                                    {advertisementList && advertisementList?.map((item, index) => {
                                        return (
                                            <div className="item img-wrap">
                                                <Link 
                                                    to={`/${item.link}`}
                                                    onClick={(e) => { e.preventDefault(); window.open(item?.link, "_blank") }}
                                                >
                                                    <img src={item.image} alt={item.title} />
                                                </Link>
                                            </div>
                                        );

                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signup
