import React, { useEffect, useRef, useState } from "react";
import HomeBanner from "../../components/HomeBanner/HomeBanner";
import "./categories.scss";
import BannerImg from "../../assets/img/category-banner.jpg";
import { Link } from "react-router-dom";
import SlideImg from "../../assets/img/WEB-BAN-02.png";
import Slider from "react-slick";
import {
  Accordion,
  Card,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import RangeSlider from "../../components/RangeSlider/RangeSlider";
import ProductImg1 from "../../assets/img/product1.png";
import ProductImg2 from "../../assets/img/product2.png";
import ProductImg3 from "../../assets/img/product3.png";
import {
  FaAngleRight,
  FaList,
  FaThLarge,
  FaShoppingCart,
} from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import productDemoImg from "../../assets/img/productDemo.jpg";
import {
  ADD_PRODUCT_TO_WISHLIST,
  ADD_TO_CART,
  GET_ADVERTISEMENT,
  GET_BRANDS_LIST,
  GET_CATEGORY_LIST,
  GET_OFFER_LIST,
  GET_PRODUCT_LIST_FOR_USER,
  globalAction,
  SET_CURRENT_COUNTRY,
  SHOW_LOADING,
} from "../../redux";
import ReactPaginate from "react-paginate";
import { IoLogoUsd, IoIosHeart } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import Loading from "../../components/Loading/Loading";
import ReactStars from "react-rating-stars-component";
import { useHistory } from "react-router-dom";
import "rc-tooltip/assets/bootstrap.css";
import * as cmRangerSlider from "rc-slider";
import "rc-slider/assets/index.css";
import { ImCross } from "react-icons/im";
import AllCountry from "../../Utilis/allCountry.json";

const { createSliderWithTooltip } = cmRangerSlider;
const Range = createSliderWithTooltip(cmRangerSlider.Range);
const { Handle } = cmRangerSlider;

function Categories(props) {
  const dispatch = useDispatch();
  const {
    advertisementList,
    categoryList,
    brandsList,
    offerList,
    productList,
    currentCountry,
    maxPriceInProductList,
  } = useSelector((state) => state.appReducer);
  const { isLoading } = useSelector((state) => state.errorReducer);
  const { user } = useSelector((state) => state.authReducer);

  const [categorySlide, SetcategorySlide] = useState(false);
  const [childMenuFlag, SetchildMenuFlag] = useState(null);
  const [toggleView, settoggleView] = useState("grid");
  const [priceRange, setPriceRange] = useState({
    minPrice: 0,
    maxPrice: Number(maxPriceInProductList),
  });
  const ref1 = useRef();

  let history = useHistory();
  let searchQuery = JSON.parse(localStorage.getItem("sQuery")); // handle search query
  let filterObj = searchQuery || {
    country_id: currentCountry?.id || 0,
    order: "desc",
    limit: 24,
  }; // check current country
  const {
    match: { params },
  } = props;
  let catSlug = params.catSlug;
  let subCatSlug = params.subCatSlug;

  const categoryShow = () => {
    SetcategorySlide(!categorySlide);
  };

  const addToCart = (item, itemQty) => {
    dispatch(globalAction(SHOW_LOADING));
    item["qty"] = itemQty || 1;
    dispatch(globalAction(ADD_TO_CART, { item: item, user: user }));
  };

  const addToWishlist = (productId) => {
    if (user) {
      dispatch(globalAction(SHOW_LOADING));
      dispatch(
        globalAction(ADD_PRODUCT_TO_WISHLIST, {
          product_id: productId,
          user_id: user?.id,
        })
      );
    } else {
      alert("Please Login with customer account");
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  useEffect(() => {
    if (advertisementList.length === 0) {
      dispatch(globalAction(GET_ADVERTISEMENT, "")); // get advertisement list
    }
    if (categoryList.length === 0) {
      dispatch(globalAction(GET_CATEGORY_LIST)); // get categories list
    }
    if (brandsList.length === 0) {
      dispatch(globalAction(GET_BRANDS_LIST)); // get brands list
    }
    if (offerList.length === 0) {
      dispatch(globalAction(GET_OFFER_LIST)); // get brands list
    }

    // check condition when user use filter or not.
    if (subCatSlug) {
      filterObj["category_slug"] = catSlug;
      filterObj["sub_category_slug"] = subCatSlug;
      dispatch(
        globalAction(GET_PRODUCT_LIST_FOR_USER, {
          filterObj: filterObj,
          history: history,
        })
      ); // get Product list according to category and subcategory slug and current county
    } else if (catSlug) {
      filterObj["category_slug"] = catSlug;
      dispatch(
        globalAction(GET_PRODUCT_LIST_FOR_USER, {
          filterObj: filterObj,
          history: history,
        })
      ); // get Product list according to category slug and current county
    } else if (localStorage.getItem("brand_id")) {
      filterObj["brand_id"] = localStorage.getItem("brand_id");
      dispatch(
        globalAction(GET_PRODUCT_LIST_FOR_USER, {
          filterObj: filterObj,
          history: history,
        })
      ); // get Product list only current country
    } else {
      dispatch(
        globalAction(GET_PRODUCT_LIST_FOR_USER, {
          filterObj: filterObj,
          history: history,
        })
      ); // get Product list only current country
    }
  }, []);

  const handleChildMenu = (parentMenuText) => {
    if (childMenuFlag === parentMenuText) {
      SetchildMenuFlag(null);
    } else {
      SetchildMenuFlag(parentMenuText);
    }
  };

  const handlePageClick = (data) => {
    dispatch(globalAction(SHOW_LOADING));
    filterObj["page"] = data.selected + 1;
    filterObj["limit"] = ref1.current.value;

    dispatch(
      globalAction(GET_PRODUCT_LIST_FOR_USER, {
        filterObj: filterObj,
        history: history,
      })
    ); // get Product list
  };

  const sideFilterHandle = (e, filterValue, filterName) => {
    console.log(filterValue, "filterValue");
    console.log(e, "e");
    console.log(filterName, "filterName");
    e.preventDefault();
    filterObj["page"] = 0;
    localStorage.removeItem("brand_id");
    delete filterObj["brand_id"];
    filterObj = JSON.parse(localStorage.getItem("filterObj")); // improve search functionality, but is not necessary
    filterObj[filterName] = filterValue;
    delete filterObj["search"]; // delete search query
    if (filterName === "sub_category_slug") {
      delete filterObj["category_slug"];
    }
    if (filterName === "category_slug") {
      delete filterObj["sub_category_slug"];
    }
    // if(filterName==='limit'){
    //   filterObj['limit']=filterValue
    // }

    dispatch(globalAction(SHOW_LOADING));
    dispatch(
      globalAction(GET_PRODUCT_LIST_FOR_USER, {
        filterObj: filterObj,
        history: history,
      })
    ); // get Product list
    SetcategorySlide(false); // close side menu on mobile
  };

  const handlePriceRange = (value) => {
    setPriceRange({ minPrice: value[0], maxPrice: value[1] });
    filterObj = JSON.parse(localStorage.getItem("filterObj")); // improve search functionality, but is not necessary
    filterObj["min_price"] = value[0];
    filterObj["max_price"] = value[1];
    filterObj["page"] = 0;
    dispatch(globalAction(SHOW_LOADING));
    dispatch(
      globalAction(GET_PRODUCT_LIST_FOR_USER, {
        filterObj: filterObj,
        history: history,
      })
    ); // get Product list
    SetcategorySlide(false); // close side menu on mobile
  };

  const resetFilter = () => {
    filterObj = { country_id: currentCountry?.id || 0, order: "desc" };
    dispatch(globalAction(SHOW_LOADING));
    dispatch(
      globalAction(GET_PRODUCT_LIST_FOR_USER, {
        filterObj: filterObj,
        history: history,
      })
    );
  };

  const handleChangeCountry = (e) => {
    filterObj["page"] = 0;
    filterObj["country_id"] = e.target.value;
    let tempSelectCountry = AllCountry.find(
      (countryItem) => countryItem?.id == e.target.value
    );
    dispatch(globalAction(SET_CURRENT_COUNTRY, tempSelectCountry));
    dispatch(globalAction(SHOW_LOADING));
    dispatch(
      globalAction(GET_PRODUCT_LIST_FOR_USER, {
        filterObj: filterObj,
        history: history,
      })
    );
  };

  useEffect(() => {
    setPriceRange({ ...priceRange, maxPrice: Number(maxPriceInProductList) });
  }, [maxPriceInProductList]);

  return (
    <section
      className={`categories home-page-main-wrap ${
        categorySlide ? "slide" : ""
      }`}
    >
      {productList?.data && (
        <>
          <HomeBanner
            imgAltText={productList?.data[0]?.category?.name}
            bannerLink="/"
            bannerText="Shop"
          />
        </>
      )}
      {/* <div className="adertise_slider" id="adertise_slider">
        <div className="container">
          <Slider {...settings}>
            {advertisementList.map((item, index) => {
              if (item.advertise_for === "category") {
                return (
                  <div className="item">
                    <a  href={`/${item.link}`}>
                      <img src={item.image} alt={item.title} />
                    </a>
                  </div>
                );
              }
            })}
          </Slider>
        </div>
      </div> */}

      <div className="categories_content my-5">
        <div className="container">
          <div className="category_aside">
            <div className="aside_content">
              <div className="close-b">
                <span
                  onClick={() => {
                    categoryShow(false);
                  }}
                >
                  <ImCross />
                </span>
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Categories</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {categoryList.length > 0 &&
                        categoryList.map((item, i) => {
                          return (
                            <li
                              className={
                                childMenuFlag === item?.name
                                  ? "--show"
                                  : "--hide"
                              }
                            >
                              <a
                                href={`/products/${item.slug}`}
                                onClick={(e) =>
                                  sideFilterHandle(
                                    e,
                                    item?.slug,
                                    "category_slug"
                                  )
                                }
                              >
                                {item?.name}
                                {/* ({item?.product_count}) */}
                              </a>
                              {item.sub_category.length > 0 && (
                                <span
                                  onClick={() => {
                                    handleChildMenu(item?.name);
                                  }}
                                >
                                  <FaAngleRight />
                                </span>
                              )}

                              {item.sub_category.length > 0 && (
                                <ul>
                                  {item.sub_category.map((item, i) => {
                                    return (
                                      <li>
                                        <a
                                          href={`/products/${item.slug}`}
                                          onClick={(e) =>
                                            sideFilterHandle(
                                              e,
                                              item?.slug,
                                              "sub_category_slug"
                                            )
                                          }
                                        >
                                          {item?.name}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Brand</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {brandsList.length > 0 &&
                        brandsList.map((item, i) => {
                          return (
                            <li>
                              <a
                                href="/"
                                onClick={(e) =>
                                  sideFilterHandle(e, item.id, "brand_id")
                                }
                              >
                                {item?.name}
                                {/* ({item.product_count}) */}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {productList?.data?.length > 0 && (
                <div className="range_slider">
                  <h3>Price Range</h3>
                  {priceRange.maxPrice && (
                    <Range
                      min={1}
                      max={Number(priceRange.maxPrice) + 1000}
                      defaultValue={[priceRange?.minPrice, priceRange.maxPrice]}
                      step={5}
                      allowCross={false}
                      tipFormatter={(value) => `${value}$`}
                      onAfterChange={(value) => handlePriceRange(value)}
                    />
                  )}

                  <div className="slide_range">
                    <p>
                      Price <span>{priceRange.minPrice}$</span> -{" "}
                      <span>
                        {priceRange.maxPrice || maxPriceInProductList}$
                      </span>
                    </p>
                  </div>
                </div>
              )}
              <div
                className="slide_range btn"
                onClick={(e) => {
                  resetFilter();
                }}
              >
                Reset Filter
              </div>
            </div>
          </div>
          <div className="category_main">
            <div className="top_bar">
              <div className="all_categories">
                <div
                  href=""
                  onClick={() => {
                    categoryShow();
                  }}
                >
                  <span>
                    <BiCategory />
                  </span>
                  <span>All Categories</span>
                </div>
              </div>
              <div className="content_left">
                {toggleView === "grid" ? (
                  <>
                    <span
                      onClick={() => {
                        settoggleView("list");
                      }}
                    >
                      <FaList />
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      onClick={() => {
                        settoggleView("grid");
                      }}
                    >
                      <FaThLarge />
                    </span>
                  </>
                )}

                <p>
                  Showing - {productList?.from} to {productList?.to} of{" "}
                  {productList?.total} results
                </p>
              </div>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    Showing the products that are selling{" "}
                    {
                      AllCountry.find(
                        (item) => item.id == filterObj?.country_id
                      )?.name
                    }
                  </Tooltip>
                }
              >
                <div className="country">
                  <div className="l-loc">
                    <span>
                      <MdLocationPin />
                    </span>
                  </div>
                  {/* {AllCountry.find((item) => item.id == filterObj.country_id).flag !=="Null" &&
                  <div className="img"> <img src={AllCountry.find((item) => item.id == filterObj.country_id).flag} alt="flag" />
                  </div>
                  } */}
                  <select
                    onChange={(e) => {
                      handleChangeCountry(e);
                    }}
                  >
                    {AllCountry.map((item, i) => {
                      return (
                        <option
                          selected={item?.id == filterObj?.country_id}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </OverlayTrigger>

              <div className="content_right">
                <select
                  ref={ref1}
                  className="count"
                  onChange={(e) => sideFilterHandle(e, e.target.value, "limit")}
                >
                  <option value="12" selected={filterObj?.limit == 12}>
                    12
                  </option>
                  <option value="24" selected={filterObj?.limit == 24}>
                    24
                  </option>
                  <option value="48" selected={filterObj?.order === 48}>
                    48
                  </option>
                </select>
                <select
                  onChange={(e) => sideFilterHandle(e, e.target.value, "order")}
                >
                  <option value="asc" selected={filterObj?.order === "asc"}>
                    Low-High
                  </option>
                  <option value="desc" selected={filterObj?.order === "desc"}>
                    High-Low
                  </option>
                </select>
              </div>
            </div>

            <div className="product_card_wrap" id="product_card_wrap">
              <Loading showLoading={isLoading} />
              {/* <CmAlert
                message={message}
                status={status}
                showAlert={visibleMessage}
              /> */}

              {toggleView === "grid" ? (
                // Grid View
                <div className="product_card grid-view">
                  {productList?.data?.length > 0 &&
                    productList?.data.map((item, index) => (
                      <>
                        <div className="card_content">
                          {item?.stock_qty < item?.minimum_qty_per_order && (
                            <div className="out-of-stock">
                              <span>Out of Stock</span>
                            </div>
                          )}
                          {item.product_variant.length > 0 ? (
                            <a href={`/product-detail/${item.slug}`}>
                              <img
                                src={
                                  item?.product_variant[0].image ||
                                  productDemoImg
                                }
                                alt={item.title}
                              />
                            </a>
                          ) : (
                            <a href={`/product-detail/${item.slug}`}>
                              <img
                                src={item.image_url || productDemoImg}
                                alt={item.title}
                              />
                            </a>
                          )}
                          <div className="pc-content">
                            <a href={`/product-detail/${item.slug}`}>
                              <h3 className="title">{item.title}</h3>
                            </a>

                            {item?.sale_price !== null ? (
                              <p>
                                <span className="saleprice">
                                  {item?.sale_price}$
                                </span>
                                <del className="regular-price">
                                  {item && item.regular_price != item.sale_price
                                    ? item.regular_price + "$"
                                    : ""}
                                </del>
                              </p>
                            ) : (
                              <p>
                                <span className="saleprice">
                                  {item?.regular_price} $
                                </span>
                              </p>
                            )}
                            {item?.user?.company_name ? (
                              <a href={`/vendor-store/${item?.user?.id}`}>
                                <p className="sold_by">
                                  <small>Sold by: </small>
                                  {item?.user?.company_name}
                                </p>
                              </a>
                            ) : (
                              <a href={`/`}>
                                <p className="sold_by">
                                  <small>Sold by: </small>
                                  Du-Afrik
                                </p>
                              </a>
                            )}

                            <div>
                              <p className="sold_by">
                                <small>Brand: </small>
                                {item.brand_name}
                              </p>
                            </div>
                            <div>
                              <p className="sold_by">
                                <small>M.no : {item.model_no}</small>
                              </p>
                            </div>
                            <div className="pc-review">
                              <span className="star-icon">
                                <ReactStars
                                  count={5}
                                  size={24}
                                  isHalf={true}
                                  edit={false}
                                  value={item?.avg_rating || 0}
                                  emptyIcon={<i className="far fa-star"></i>}
                                  halfIcon={
                                    <i className="fa fa-star-half-alt"></i>
                                  }
                                  fullIcon={<i className="fa fa-star"></i>}
                                  activeColor="#ffd700"
                                />
                              </span>
                              <span className="review-count">
                                ({item?.product_review_count || 0} Reviews)
                              </span>
                            </div>
                          </div>
                          <div className="button">
                            {item?.stock_qty > item?.minimum_qty_per_order && (
                              <button
                                className="btn cart"
                                onClick={() =>
                                  addToCart(item, item?.minimum_qty_per_order)
                                }
                              >
                                <FaShoppingCart />
                              </button>
                            )}
                            <button
                              className="btn wish"
                              onClick={() => {
                                addToWishlist(item?.id);
                              }}
                            >
                              <IoIosHeart />
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              ) : (
                // List View
                <div className="product_card list-view">
                  {productList?.data?.length > 0 &&
                    productList?.data.map((item, index) => (
                      <div className="card_content">
                        {item?.stock_qty < item?.minimum_qty_per_order && (
                          <div className="out-of-stock">
                            <span>Out of Stock</span>
                          </div>
                        )}
                        <div className="img_wrap">
                          {item.product_variant.length > 0 ? (
                            <a href={`/product-detail/${item.slug}`}>
                              <img
                                src={
                                  item?.product_variant[0].image ||
                                  productDemoImg
                                }
                                alt={item.title}
                              />
                            </a>
                          ) : (
                            <a href={`/product-detail/${item.slug}`}>
                              <img
                                src={item.image_url || productDemoImg}
                                alt={item.title}
                              />
                            </a>
                          )}
                        </div>
                        <div className="pc-content">
                          <a href={`/product-detail/${item.slug}`}>
                            <h3 className="title">{item.title}</h3>
                          </a>

                          {item?.sale_price !== null ? (
                            <p>
                              <span className="saleprice">
                                {item?.sale_price}$
                              </span>
                              <del className="regular-price">
                                {item && item.regular_price != item.sale_price
                                  ? item.regular_price + "$"
                                  : ""}
                              </del>
                            </p>
                          ) : (
                            <p>
                              <span className="saleprice">
                                {item?.regular_price} $
                              </span>
                            </p>
                          )}
                          {item?.user?.company_name ? (
                            <a href={`/vendor-store/${item?.user?.id}`}>
                              <p className="sold_by">
                                <small>Sold by: </small>
                                {item?.user?.company_name}
                              </p>
                            </a>
                          ) : (
                            <a href={`/`}>
                              <p className="sold_by">
                                <small>Sold by: </small>
                                Du-Afrik
                              </p>
                            </a>
                          )}
                          <div className="pc-review">
                            <span className="star-icon">
                              <ReactStars
                                count={5}
                                size={24}
                                isHalf={true}
                                edit={false}
                                value={item?.avg_rating || 0}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={
                                  <i className="fa fa-star-half-alt"></i>
                                }
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                              />
                            </span>
                            <span className="review-count">
                              ({item?.product_review_count || 0} Reviews)
                            </span>
                          </div>
                        </div>
                        <div className="button">
                          {item?.stock_qty > item?.minimum_qty_per_order && (
                            <button
                              className="btn cart"
                              onClick={() =>
                                addToCart(item, item?.minimum_qty_per_order)
                              }
                            >
                              <FaShoppingCart />
                            </button>
                          )}
                          <button
                            className="btn wish"
                            onClick={() => {
                              addToWishlist(item?.id);
                            }}
                          >
                            <IoIosHeart />
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              )}

              {productList?.data?.length > 0 ? (
                <div className="pcw-pagination">
                  <div className="pcw-pagination-inner">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        productList?.total / productList?.per_page
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={Number(productList?.current_page - 1)}
                    />
                  </div>
                </div>
              ) : (
                <div className="not-product">
                  <h4>No Products Found...!</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Categories;
