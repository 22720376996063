import React from "react";
import Chart from "react-google-charts";
import MaterialTable from "material-table";
import "./OrderManagement.scss";
import tableIcons from "../../../Dashboard/TableIcons";
import { getOrderListByVendorIdApi } from "../../../../api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";

function CancelledOrder() {
  const {user} = useSelector(state => state.authReducer);
  const orderData = [
    { orderType: "Basic Order", orderId: "1", Qty: "3", TotalAmount: "$40" },
    { orderType: "Shoes", orderId: "2", Qty: "1", TotalAmount: "$40" },
    { orderType: "Shots", orderId: "3", Qty: "1", TotalAmount: "$40" },
    { orderType: "na", orderId: "4", Qty: "5", TotalAmount: "$40" },
  ];
  const orderTitle = [
    { title: "Order Id", render: (rowData) => <Link to={`/vendorDashboard/order-detail/${rowData.id}`}>{rowData?.order_number}</Link>, field: "OrderID" },
    { title: "Total Price",render: (rowData) =>'$'+rowData.order_price, field: "order_price" },
    { title: "Payment Mode", field: "payment_type" },
    { title: "Payment Status", field: "payment_status" },
    { title: "Order status", field: "order_status" },
    { title: "Order Date", field: "order_at" },
    {
      title: "Actions",
      render: (rowData) => (  
        <Link 
        to={`/vendorDashboard/order-detail/${rowData.id}`}>
          <FaEye />
        </Link>
      ),
      field: "CancelOrder",
    },
  ];

  return (
    <div className="vd-dashboard-wrap order-management">
      <div className="title">
        <h3>Cancelled Orders</h3>
      </div>
      <div className="vd-dashboard-body">
        <div className="order_chart_warp box-size">
          {/* <div className="order_chart">
            <Chart
              width={"100%"}
              height={"300px"}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={[
                ["Year", "Sales", "Expenses", "Profit"],
                ["2014", 1000, 400, 200],
                ["2015", 1170, 460, 250],
                ["2016", 660, 1120, 300],
                ["2017", 1030, 540, 350],
              ]}
              options={{
                // Material design options
                chart: {
                  title: "Company Performance",
                  subtitle: "Sales, Expenses, and Profit: 2014-2017",
                },
              }}
              // For tests
              rootProps={{ "data-testid": "1" }}
            />
          </div> */}
        </div>
        <div className="all_orders box-size">
          <div className="data-table">
          <MaterialTable
          title="All Orders"
          columns={orderTitle}
          icons={tableIcons}
          options={{
            search: true,
            // exportButton: true,
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No records to display',
            }
          }}
          data={query =>
            new Promise((resolve, reject) => {
              console.log(query)
              let params = `?vendor_id=${user?.id}&status=cancelled`
              params += `&page=${query.page + 1}`
              params += `&limit=${query.pageSize}`
              if (user) {
  
                getOrderListByVendorIdApi(params)
                  .then((res) => {
                    let { data, status } = res;
                    if (status === 200) {
                      resolve({
                        data: data?.data?.data,
                        page: data?.data?.current_page - 1,// current page number
                        totalCount: data?.data?.total, // total row number
                      });
                    }
                  })

              }
            })

          }
        />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelledOrder;
